<!-- section start -->
<!--<section [hidden]="true" class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                              <span class="filter-back">
                                <i class="fa fa-angle-left" aria-hidden="true"></i> back
                              </span>
                            </div>
                            <app-categories></app-categories>
                        </div>
                        <div class="collection-filter-block">
                            <app-services></app-services>
                        </div>
                        <app-product-box-vertical-slider
                                [title]="'New product'"
                                [type]="product?.type">
                        </app-product-box-vertical-slider>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 col-xs-12">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="filter-main-btn mb-2">
                                  <span class="filter-btn" (click)="toggleMobileSidebar()">
                                    <i class="fa fa-bars" aria-hidden="true"></i> sidebar
                                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="product-slick">
                                    <ng-container *ngFor="let image of product.images; index as i">
                                        <ng-template carouselSlide [id]="i">
                                            <div>
                                                <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                     [lazyLoad]="image.src"
                                                     [alt]="image.alt" class="img-fluid" [src]="image.src">
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="slider-nav">
                                            <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                                                <ng-container *ngFor="let image of product.images; index as i">
                                                    <ng-template carouselSlide [id]="i">
                                                        <div class="owl-thumb" [class.active]="i == activeSlide">
                                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                 [lazyLoad]="image.src"
                                                                 [alt]="image.alt" class="img-fluid" [src]="image.src" (click)="owlCar.to(activeSlide = i.toString())">
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                            </owl-carousel-o>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 rtl-text">
                                <div class="product-right">
                                    <h2>{{ product?.title }}</h2>
                                    <h4>
                                        <ng-container *ngIf="product?.discount">
                                            <del>{{ product?.price * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}</del>
                                            <span>{{ product?.discount }}% off</span>
                                        </ng-container>
                                    </h4>
                                    <h3>
                                        {{ (product?.price | discount:product) * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}
                                    </h3>
                                    <app-stock-inventory [stock]="product.stock"></app-stock-inventory>
                                    <div class="product-buttons">
                                        <a href="javascrip:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a>
                                        <a href="javascrip:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="buyNow(product)">buy now</a>
                                    </div>
                                    <div class="border-product">
                                        <h6 class="product-title">product details</h6>
                                        <p [innerText]="product.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="tab-product m-0">
                        <div class="row">
                            <div class="col-sm-12 col-lg-12">
                                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>Description</a>
                                        <ng-template ngbNavContent>
                                            <div [innerHtml]="product.long_product_description"></div>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="3">
                                        <a ngbNavLink>Write Review</a>
                                        <ng-template ngbNavContent>
                                            <form class="theme-form">
                                                <div class="row">
                                                    <div class="col-md-12 ">
                                                        <div class="media rating-sec">
                                                            <label>Rating</label>
                                                            <div class="media-body ms-3">
                                                                <div class="rating three-star">
                                                                    <i class="fa fa-star"></i>
                                                                    <i class="fa fa-star"></i>
                                                                    <i class="fa fa-star"></i>
                                                                    <i class="fa fa-star"></i>
                                                                    <i class="fa fa-star"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="name">Name</label>
                                                        <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="email">Email</label>
                                                        <input type="text" class="form-control" id="email" placeholder="Email" required>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label for="review">Review Title</label>
                                                        <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label for="review">Review Title</label>
                                                        <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav" class="mt-2"></div>
                            </div>
                        </div>
                    </section>
                </div>


            </div>
        </div>
    </div>
    <app-related-product [type]="product?.type"></app-related-product>
</section>-->

<app-product-left-sidebar></app-product-left-sidebar>

<!--<app-size-modal #sizeChart [product]="product"></app-size-modal>-->
<!-- Section ends -->
