<!--<app-header-one (resultSearch)="filter($event)" [sticky]="true"></app-header-one>-->
<app-header-four (resultSearch)="filter($event)" [sticky]="true"></app-header-four>
<app-breadcrumb [title]="title" [breadcrumb]="" [breadcrumbDynamic]="sendBreadcrumbDynamic"></app-breadcrumb>
<app-category *ngIf="title == 'products'" ></app-category>
<router-outlet></router-outlet>
<!-- search section start-->
<!--<div *ngIf="userSearch" class="title1 title-gradient section-t-space">
    <h2 class="title-inner1">Resultados</h2>
</div>
<section #boxSearch class="p-t-0 section-b-space gym-product">
    <div *ngIf="userSearch" class="container">
        <div class="row partition-cls">
            <div class="col-xl-3 col-sm-6 d-grid my-2" *ngFor="let product of resultSearch">
                <div class="product-box d-grid align-content-center">
                    <app-product-box-one
                            [product]="product"
                            [loader]="true"
                            [href]="true"
                            [currency]="productService?.Currency"
                            [cartModal]="true">
                    </app-product-box-one>
                </div>
            </div>
            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!resultSearch.length">
                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                <h3>¡Lo sentimos! No se ha encontrado el producto que buscaba. </h3>
                <a [routerLink]="['/products']" class="btn btn-solid">seguir comprando</a>
            </div>
        </div>
    </div>
</section>-->
<!-- search section end-->
<!--<app-cart-variation [direction]="'top'"></app-cart-variation>-->
<app-footer-one [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-footer-one>

