<ngx-loading-bar></ngx-loading-bar>
<ng-container *ngIf="!maintenance_page">
    <router-outlet></router-outlet>
</ng-container>
<app-tap-to-top></app-tap-to-top>
<app-whatsapp *ngIf="showLogoWhatsapp"></app-whatsapp>

<!--<app-layout-box></app-layout-box>-->
<ng-container *ngIf="maintenance_page">
    <section class="" style="height: 100vh">
        <div class="container">
            <div id="container" class="text-center">
                <div>
                    <div id="login">
                        <div>
                            <div class="logo mb-4">
                                <a [routerLink]="['/']">
                                    <img [src]="logo" alt="" style="max-width: 25vh" class="img-fluid">
                                </a>
                            </div>
                            <h2 class="mb-3">
                                {{ txtLabel }}
                            </h2>
                        </div>
                    </div>
<!--                    <div id="powered">-->
<!--                        <p>© {{ Year }}, Powered by IanOS.</p>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </section>
</ng-container>
