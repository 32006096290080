import { Component, OnInit } from '@angular/core';
import { CategorySlider } from '../../../shared/data/slider';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "../../../services/http.service";
import { environment } from "../../../../environments/environment";

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
    id: string = '';
    url: string = '';
    global = environment;
    loadGif: string = environment.loadGif;
    categories: any[] = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private service: HttpService,
    ) {
        this.url = this.router.url;
        this.id = this.url.substring(this.url.lastIndexOf('/') + 1);
        this.service.getCategories().subscribe((res) => {
            this.categories = res.filter(item => item.category_active == true && item.category_disabled == true);
        });
    }

    public CategorySliderConfig: any = CategorySlider;

    ngOnInit(): void {
    }

}
