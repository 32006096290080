<!--<app-breadcrumb [title]="'Cart'" [breadcrumb]="'Cart'"></app-breadcrumb>-->
<!-- section start -->
<section class="cart-section section-b-space">
    <div class="container">
        <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!products.length">
        <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
        <h3><strong>Su carrito está vacío</strong></h3>
        <h4>Añade algo para hacerme feliz :)</h4>
        </div>
        <div class="row" *ngIf="products.length">
        <div class="col-sm-12">
            <table class="table cart-table table-responsive-xs">
                <thead>
                    <tr class="table-head">
                        <th scope="col">imagen</th>
                        <th scope="col">nombre del producto</th>
                        <th scope="col">precio</th>
                        <th scope="col">cantidad</th>
                        <th scope="col">acción</th>
                        <th scope="col">total</th>
                    </tr>
                </thead>
                <tbody *ngFor="let product of products">
                    <tr>
                        <td>
                            <a [href]="['products'+product?.url_product]">
                                <img [src]="product.images[0].src" [alt]="product.images[0].alt">
                            </a>
                        </td>
                        <td>
                            <a [href]="['products'+product?.url_product]">{{ product.title | titlecase }}</a>
                            <div class="mobile-cart-content row">
                                <div class="col">
                                    <div class="qty-box">
                                        <div class="input-group">
                                            <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="product.quantity">
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <h2 class="td-color">
                                        {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                    </h2>
                                </div>
                                <div class="col">
                                    <h2 class="td-color">
                                        <a (click)="removeItem(product)" class="icon">
                                            <i class="ti-close"></i>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                        </td>
                        <td>
                            <h2>
                                {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                            </h2>
                        </td>
                        <td>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement(product)">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="product.quantity">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus"  data-type="plus" (click)="increment(product)">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <a href="javascript:void(0)" (click)="removeItem(product)" class="icon">
                            <i class="ti-close"></i>
                        </a>
                        </td>
                        <td>
                            <h2 class="td-color">
                                {{ ((product.price | discount:product) * product.quantity) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                            </h2>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table cart-table table-responsive-md">
                <tfoot>
                    <tr>
                        <td>precio total :</td>
                        <td>
                            <h2> {{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</h2>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        </div>
        <div class="row cart-buttons" *ngIf='products.length'>
            <div class="col-6">
                <a [routerLink]="['/products']" class="btn btn-solid">seguir comprando</a>
            </div>
            <div class="col-6">
                <a (click)="verify()" class="btn btn-solid">check out</a>
            </div>
        </div>
    </div>
</section>
<!--section end-->

