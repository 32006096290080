import {Component, OnInit, Input, HostListener, Output, EventEmitter} from '@angular/core';
import {HttpService} from "../../../services/http.service";

@Component({
    selector: 'app-header-one',
    templateUrl: './header-one.component.html',
    styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

    @Input() class: string;
    @Input() themeLogo = 'https://firebasestorage.googleapis.com/v0/b/ch-tienda-online-app-dev.appspot.com/o/assets%2Fimages%2Flogos%2Fyourlogo.png?alt=media&token=1e815d03-1b4c-47a0-9f8e-55ba3ee7d92c'; // Default Logo
    @Input() topbar = true; // Default True
    @Input() sticky = false; // Default false
    public color: string = '';
    @Output() resultSearch : EventEmitter<any> = new EventEmitter<any>();
    public stick = false;
    public contentSearch: string = '';
    public boxSearch: boolean = false;

    constructor(
        private service: HttpService,
    ) {
        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            this.themeLogo = res.header.logo.default;
            this.color = res.primaryColor;
        });
    }

    ngOnInit(): void {

    }

    // @HostListener Decorator
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.stick = number >= 150 && window.innerWidth > 400;
    }

    search() {
        console.log('search', this.contentSearch);
        this.resultSearch.emit(this.contentSearch);
    }

    showSearch(event) {
        this.boxSearch = event;
    }
}
