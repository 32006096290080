import {Component, ViewChild} from '@angular/core';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent {


    constructor() {
        console.log('helloooo')

    }

    ngOnInit(): void {
    }

}
