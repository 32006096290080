import {Component, Input} from '@angular/core';
import {Product} from "../../shared/classes/product";

@Component({
    selector: 'app-subcategories',
    templateUrl: './subcategories.component.html',
    styleUrls: ['./subcategories.component.scss']
})
export class SubcategoriesComponent {

    @Input() idSubcategory : string;
    @Input() title : string = 'Subcategoria';
    @Input() subcategories : [];
    @Input() href : boolean = false;
    public products: Product[] = [];
    public collapse: boolean = true;

    constructor() {
    }

    ngOnInit(): void {
    }
}
