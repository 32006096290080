import {Component, OnInit} from '@angular/core';
import {NavService, Menu} from '../../services/nav.service';
import {Router} from '@angular/router';
import {FirestoreService} from "../../../services/firestore.service";
import {HttpService} from "../../../services/http.service";

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

    public menuItems: Menu[];

    constructor(
        private router: Router,
        private firestore: FirestoreService,
        private service: HttpService,
        public navServices: NavService
    ) {
        // this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems);
        this.router.events.subscribe((event) => {
            this.navServices.mainMenuToggle = false;
        });
        this.service.getCategories().subscribe(res => {
            this.menuItems = [];
            // console.table('categories', res);
            res.forEach(item => {
                if (item.category_active) {
                    item.title = item.category_name;
                    item.type = 'link';
                    item.path = `products/${item.url_category}`;
                    this.service.getCategoryByUrl(item.url_category).subscribe(response => {
                        if (response.success && response.subcategories.length > 0) {
                            item.path       = `products/${response.category.url_friendly}`;
                            item.type       = 'sub';
                            item.active     = false;
                            item.children   = [];
                            response.subcategories.forEach( element => {
                               element.title    = element.name;
                               element.type     = 'link';
                               element.path     = `${item.path}/${element.url}`;
                               item.children.push(element);
                            })

                        }
                    })

                    this.menuItems.push(item);
                }
            })
        });
    }

    ngOnInit(): void {
    }

    leftMenuToggle(): void {
        this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
    }

    // Click Toggle menu (Mobile)
    toggletNavActive(item) {
        item.active = !item.active;
    }

    onHover(menuItem) {
        if (window.innerWidth > 1200 && menuItem) document.getElementById('unset').classList.add('sidebar-unset');
        else document.getElementById('unset').classList.remove('sidebar-unset');
    }

}
