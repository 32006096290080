<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="d-flex">
                            <a class="btn btn-outline mx-1" *ngIf="data.sessions && data.sessions.google"
                                (click)="login(2)"><i aria-hidden="true" class="fa fa-google"></i> &nbsp; Continuar con
                                Google </a>
                            <a class="btn btn-outline blue-solid mx-1" *ngIf="data.sessions && data.sessions.facebook"
                                (click)="login(3)"><i aria-hidden="true" class="fa fa-facebook-official"></i> &nbsp;
                                Continuar con facebook </a>
                        </div>
                        <hr>
                        <div class="form-group mt-3">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" [(ngModel)]="email" id="email" name="email"
                                placeholder="Email" required="">
                        </div>
                        <div class="form-group">
                            <label for="review">Password</label>
                            <div class="input-group mb-2">
                                <input [type]="typePassword.password" class="form-control mb-0" [(ngModel)]="password"
                                    id="review" name="review" placeholder="Introduzca su contraseña" required="">
                                <div class="input-group-text cursor-pointer" (click)="viewPassword()">
                                    <i aria-hidden="true" class="fa fa-eye"></i>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-solid mx-1 mt-3" (click)="login(1)">Login</button>
                        <button class="btn btn-solid warning mx-1 mt-3" (click)="openVerticallyCentered(content)">Restablecer
                            contraseña</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>Nuevo cliente</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Crear una cuenta</h6>
                    <p>Regístrese gratis en nuestra tienda. El registro es rápido y sencillo. Le permite realizar
                        pedidos en nuestra tienda. Para empezar a comprar haga clic en crear una cuenta.</p>
                    <a [routerLink]="'/pages/register'" class="btn btn-solid">Crear una cuenta</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Restablecer contraseña</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div>
            <input type="text" name="email" [formControl]="ageVerificationForm.controls['email']" value=""
                class="form-control" placeholder="Introduzca su correo electrónico">
        </div>
    </div>
    <div class="modal-footer">
        <button [disabled]="!ageVerificationForm.valid" type="submit" (click)="sendResetPassword()"
            class="btn btn-solid mx-1">
            enviar
        </button>
        <button type="submit" (click)="modal.close('Close click')" class="btn btn-solid-danger mx-1">
            cancelar
        </button>
    </div>
</ng-template>

<ng-template #content2 let-modal2>
    <div class="modal-header">
        <h4 class="modal-title">Estimado usuario</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal2.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div>
            <p> Debido a una actualización en nuestra tienda web, se requiere que actualice su contraseña para poder
                seguir accediendo a su cuenta. Por favor, ingrese una nueva contraseña.</p>
            <br>
            <p> Muchas gracias por su comprensión.</p>
        </div>
        <div class="form-group col">
            <div class="field-label">Contraseña</div>
            <input type="password" name="password" id="password" [(ngModel)]="newPassword" value="" class="form-control"
                placeholder="Ingresar nueva contraseña">
            <div class="field-label mt-2">Confirmar Contraseña</div>
            <input type="password" name="passwordConfirm" id="passwordConfirm" [(ngModel)]="newPasswordConfirm" value=""
                class="form-control" placeholder="Confirmar contraseña">
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="updatePassword()" type="submit" class="btn btn-solid mx-1">
            Actualizar contraseña
        </button>
        <button type="submit" (click)="modal2.close('Close click')" class="btn btn-solid-danger mx-1">
            cancelar
        </button>
    </div>
</ng-template>