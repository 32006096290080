<app-breadcrumb [title]="'Lookbook'" [breadcrumb]="'Lookbook'"></app-breadcrumb>
<!-- section start -->
<section class="lookbook section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="lookbook-block">
                    <img src="assets/images/lookbook.jpg" class="img-fluid w-100" alt="">
                    <div class="lookbook-dot">
                        <span>1</span>
                        <a [routerLink]="['/home/fashion']">
                            <div class="dot-showbox">
                                <img src="assets/images/product/fashion/1.jpg" class="img-fluid" alt="">
                                <div class="dot-info">
                                    <h5 class="title">tee</h5>
                                    <h5>200$</h5>
                                    <h6>details</h6>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="lookbook-dot dot2">
                        <span>2</span>
                        <a [routerLink]="['/home/fashion']">
                            <div class="dot-showbox">
                                <img src="assets/images/product/fashion/2.jpg" class="img-fluid" alt="">
                                <div class="dot-info">
                                    <h5 class="title">tee</h5>
                                    <h5>200$</h5>
                                    <h6>details</h6>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="lookbook-block">
                    <img src="assets/images/lookbook2.jpg" class="img-fluid w-100" alt="">
                    <div class="lookbook-dot dot3">
                        <span>1</span>
                        <a [routerLink]="['/home/fashion']">
                            <div class="dot-showbox">
                                <img src="assets/images/product/fashion/2.jpg" class="img-fluid" alt="">
                                <div class="dot-info">
                                    <h5 class="title">tee</h5>
                                    <h5>200$</h5>
                                    <h6>details</h6>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="lookbook-dot dot4">
                        <span>2</span>
                        <a [routerLink]="['/home/fashion']">
                            <div class="dot-showbox">
                                <img src="assets/images/product/fashion/2.jpg" class="img-fluid" alt="">
                                <div class="dot-info">
                                    <h5 class="title">tee</h5>
                                    <h5>200$</h5>
                                    <h6>details</h6>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->