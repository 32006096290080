<app-breadcrumb [title]="'FAQ'" [breadcrumb]="'FAQ'"></app-breadcrumb>
<!-- section start-->
<section class="faq-section section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion theme-accordion" id="accordionExample">
                    <ngb-panel id="static-1" title=" ¿Qué debo hacer para comprar en store.centralhidraulica.com?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Para realizar compras en store.centralhidraulica.com, deberá estar registrado. Para crear una nueva cuenta debe dar clic en Ingresar / Registrarse y elegir la opción Soy nuevo cliente que aparece en la parte izquierda
                                de la página. Una vez realizado el registro podrá seleccionar todos los productos que desee adquirir y agregarlos al carrito de compras, al concluir su selección, sólo debe dar clic en Agregar y seguir estos sencillos
                                pasos:
                            </p>
                            <div class="col-lg-12" style="padding-left: 2rem">
                                <ul>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Envío. Elige la dirección de envío de su pedido.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Pago. Selecciona la forma de pago</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Revisar. Verifica toda la información del pedido</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Terminar. Concluye la compra</li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-2" title=" ¿Para qué debo registrarme en el sitio?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Al registrarse en el sitio store.centralhidraulica.com, usted podrá acceder a las siguientes ventajas:
                            </p>
                            <div class="col-lg-12" style="padding-left: 2rem">
                                <ul>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Realizar compras en nuestra tienda virtual</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Obtener un servicio personalizado al realizar sus compras.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Recibir promociones especiales por correo electrónico.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Administrar su perfil de usuario: compras en línea, estatus de pedido, direcciones de envío y formas de pago.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Registrar más de una dirección de facturación y envío.</li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-3" title="¿Cuáles son las formas de pago?">
                        <ng-template ngbPanelContent>
                            <div class="col-lg-12" style="padding-left: 2rem">
                                <ul>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Tarjeta de Crédito vía telefónica</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Transferencia Bancaria</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Pago en Sucursales</li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-4" title=" ¿Cuáles son los gastos de envío?">
                        <ng-template ngbPanelContent>
                            <div class="col-lg-12" style="padding-left: 2rem">
                                <ul>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Central Hidráulica ofrece el envío gratis a nuestras sucursales, para que usted pueda pasar a retirar el producto en la sucursal que mejor le
                                        convenga.
                                    </li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>La tarifa de envío a domicilio en l zona metropolitana y el interior del país es de $15.00 + IVA.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>El cálculo de la tarifa para envíos internacionales se realiza en base al peso o volumen del artículo.</li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-5" title="¿Cuánto es el tiempo de entrega?">
                        <ng-template ngbPanelContent>
                            <div class="col-lg-12" style="padding-left: 2rem">
                                <ul>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Zona metropolitana de San Salvador y en nuestras sucursales, tiempo de entrega 48 horas.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Interior del país (El Salvador), tiempo de entrega 72 horas.</li>
                                    <li class="d-block"><i class="fa fa-angle-double-right me-2"></i>Fuera de El Salvador, tiempo de entrega es de 72 horas.</li>
                                </ul>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-6" title="¿Cuál es el nivel de seguridad para comprar?">
                        <ng-template ngbPanelContent>
                            <p class="mb-0">
                                Toda la información que sea registrada en nuestro sitio web, es debidamente encriptada bajo estándares de seguridad utilizados en la mayoría de tiendas Online a nivel mundial; toda la información es enviada a nuestra
                                base de datos de forma segura. Estos datos no pueden ser leídos de ninguna manera durante su transmisión. Al recibir la información, es almacenada y codificada en una base de datos, alojada en un servidor, preservando
                                la seguridad de los mismos después de su transmisión. Por políticas internas la información no será transferida a terceros.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-7" title="¿Dónde puedo recibir mi pedido?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Los productos vendidos a través de nuestra plataforma store.centralhidraulica.com se pueden enviar a todo el territorio salvadoreño y países de la región centroamericana, el caribe, México y Sur América.
                                <br><br>
                                Si su dirección está fuera de los límites del territorio salvadoreño, los posibles derechos de aduana y demás impuestos aplicables a la importación durante el proceso de compra, así como el despacho aduanero (control
                                aduanero y liquidación de los pertinentes tributos) correrán a su cargo.
                            </p>
                            <div class="typo-content pl-1">
                                <dl>
                                    <dt>a) Envío a domicilio.</dt>
                                    <dd class="pl-1"> Se realizan entregas a domicilio en todo El Salvador, se aplicará un cargo que se verá reflejado en su pedido y facturación; para mayor información refiérase a la pregunta ¿cuáles son los gastos
                                        de envío?
                                    </dd>
                                    <dt> b) Envío a una de nuestras sucursales:</dt>
                                    <dd class="pl-1"> Podrá recoger sus artículos adquiridos por medio de store.centralhidraulica.com en cualquiera de nuestras 4 sucursales. Para hacerlo deberá especificarlo en el paso “Envío” del proceso de
                                        finalización de su
                                        compra.
                                    </dd>
                                    <dt> c) Envío Internacional.</dt>
                                    <dd class="pl-1"> Todas las compras que se realicen un nuestra plataforma y que deban ser enviadas fuera del territorio salvadoreño, serán gestionadas a través de DHL. (ver Políticas de envío internacional).</dd>
                                </dl>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-8" title="¿Cuál es el proceso de entrega en tienda?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                En caso de haber elegido la entrega en tienda, le comunicaremos la llegada de tu pedido a través de un correo electrónico. Es necesario presentar el número de pedido y el DUI de la persona que vaya a recogerlo, en caso
                                de no ser la persona que realizó la compra se necesitará un consentimiento firmado por el comprador especificando su número de DUI.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-9" title="¿Cuál es el proceso de entrega a domicilio?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Si al momento de efectuar la compra en nuestra plataforma store.centralhidraulica.com, usted eligió el envío a domicilio, recibirá una llamada de uno de nuestros agentes de atención al cliente para confirmar su compra,
                                dirección de envío y cualquier otro dato que se considere necesario para hacerle llegar los artículos adquiridos; a partir de ese momento se estaría realizando el despacho del producto, y lo estaría recibiendo en un
                                lapso no mayor a 48 horas. Será necesario que se tenga a la mano un documento con fotografía de la persona a quien usted haya designado para la recepción del producto.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-10" title="¿Puedo cancelar la compra?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Al revisar su pedido y antes de firmar de aceptado, si no está conforme o completamente satisfecho de su compra, puede devolver el producto siempre y cuando se encuentre en las condiciones originales (envoltorios,
                                cajas, etc., en perfecto estado). En el momento que lo recoge en tienda o en el momento de su entrega a domicilio. Una vez aceptado el producto, y firmado el documento de envío, no se aceptarán devoluciones después de
                                24 horas de entregado, siempre y cuando se encuentren en las condiciones originales (envoltorios, cajas, etc., en perfecto estado). Su dinero será reintegrado 72 horas después de haber recibido el artículo y haya
                                pasado por revisión del departamento de producción.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-11" title="¿Cómo confirmo el depósito de mi pago?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Es necesario que nos envíe copia de su abono a cuenta al correo electrónico ventas.online@centralhidraulica.com indicándonos el número de pedido generado por nuestra plataforma store.centralhidraulica.com.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-12" title="¿Cómo confirmo y/o doy seguimiento a mi pedido?">
                        <ng-template ngbPanelContent>
                            <p class="">
                                Para revisar el estatus de cualquier compra que haya realizado en store.centralhidraulica.com pedimos que se registre (inicie sesión) y luego debe ingresar en la sección "Mi Cuenta", que aparece en la parte superior
                                derecha de cada página, una vez dentro, en "estado de tus pedidos", nuevamente ingrese mediante un clic; ahí encontrará el estatus de su compra. Igualmente puede llamarnos al +503 2133-7315 con nuestro Personal de
                                Atención al Cliente (PAC). Antes de 24 horas uno de nuestros asesores se comunicará para confirmar tu pedido.
                            </p>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->
