import {Component, OnInit, Injectable, PLATFORM_ID, Inject, Output, EventEmitter} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ProductService} from "../../services/product.service";
import {Product} from "../../classes/product";
import {Router} from "@angular/router";
import {User} from "../../classes/user";
import {AuthService} from "../../services/auth.service";
import {ToastrService} from "ngx-toastr";
import Swal from 'sweetalert2';
import {NavService} from "../../services/nav.service";
import {HttpService} from "../../../services/http.service";
import {environment} from "../../../../environments/environment";
import {FirestoreService} from "../../../services/firestore.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public products: Product[] = [];
    public search: boolean = false;
    user = new User();
    public nameUser: string = '';
    public load: string = environment.loadGif;
    public iconSearch: string = 'assets/images/icon/search.png';
    public iconUser: string = 'assets/images/icon/icon-user.png';
    public iconCart: string = 'assets/images/icon/icon-cart.png';

    public languages = [{
        name: 'English',
        code: 'en'
    }, {
        name: 'French',
        code: 'fr'
    }];

    public currencies = [{
        name: 'Euro',
        currency: 'EUR',
        price: 0.90 // price of euro
    }, {
        name: 'Rupees',
        currency: 'INR',
        price: 70.93 // price of inr
    }, {
        name: 'Pound',
        currency: 'GBP',
        price: 0.78 // price of euro
    }, {
        name: 'Dollar',
        currency: 'USD',
        price: 1 // price of usd
    }]
    private href: string = '';
    public isLogged: boolean = false;
    showInfo: boolean = false;
    @Output() showSearch : EventEmitter<any> = new EventEmitter<any>();
    sub: Subscription;
    collectionName = 'Products';
    collectionField = 'product_name';
    orderByDir: any = 'asc';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private translate: TranslateService,
        private router: Router,
        private auth: AuthService,
        private toastrService: ToastrService,
        public productService: ProductService,
        private service: HttpService,
        private firestore:  FirestoreService,
    ) {
        // this.sub = this.firestore.search(this.collectionName, {
            // 'orderByName': this.collectionField,
            // 'orderByDir': this.orderByDir,
        // }).subscribe(res1 => {
            this.productService.updateCart();
        // })
        this.productService.cartItems.subscribe(response => this.products = response);
    }

    ngOnInit(): void {
        // this.productService.updateDataShoppingCart();
        this.service.sendGET_SettingsApp('Store').subscribe( res => {
            this.iconSearch = res.header.iconSearch;
            this.iconUser   = res.header.iconUser;
            this.iconCart   = res.header.iconCart;
            this.setColorIconUser(res, 'user-icon');
            this.setColorIconUser(res, 'cart-icon');
        })
        this.productService.checkDataShoppingCart();


    }

    setColorIconUser(res, icon){
        let x: any = document.getElementById(icon);
        if (x){
            let y = (x.contentWindow || x.contentDocument);
            if (y){
                if (y.document) y = y.document;
                let polygonElement = y.querySelectorAll('.cls-1');
                // Cambia el estilo fill
                polygonElement.forEach(element => {
                    element.style.fill = res.header.fontColor;
                });
            }
        }
    }

    searchToggle() {
        this.search = !this.search;
        this.showSearch.emit(this.search);
    }

    changeLanguage(code) {
        if (isPlatformBrowser(this.platformId)) {
            this.translate.use(code)
        }
    }

    get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    changeCurrency(currency: any) {
        this.productService.Currency = currency
    }

    async login() {
        this.href = this.router.url;
        console.log('history', this.router.url);
        localStorage.setItem('history', this.href)
        await this.router.navigate(['/pages/mi-cuenta']);
    }

    signOut() {
        this.toastrService.info('logging out, please wait');
        this.auth.logout().then((e) => {
            this.isLogged = false;
            window.location.href = '/';
        }).catch((e) => {
            console.log('catch signOut', e);
        });
    }

    verifySession(){
        // this.auth.getUser2().then( userLocal => {
        //     this.isLogged = true;
        //     console.log("userLocal: ", userLocal);
        //     this.user = userLocal;
        //     this.nameUser = this.user.firstName == null || this.user.firstName == '' ? this.user.email : this.user.firstName;
        //     this.showInfo = true;
        // }).catch( e => {
        //     this.showInfo = true;
        // })
        this.router.navigate(['/pages/login']);
    }

}
