import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { addressCompany, Direction_User, User } from '../../../shared/classes/user';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { FirestoreService } from '../../../services/firestore.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/http.service';
import { ViewportScroller } from '@angular/common';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { CommonService } from '../../../services/common.service';
import { environment } from '../../../../environments/environment';
import { routerLinkWithHref } from '@angular/core/schematics/migrations/router-link-with-href/util';
import { HttpClient } from '@angular/common/http';
import { MapsAPILoader } from '@agm/core';

interface Country {
    name: string;
    alpha2Code: string;
    flag: string;
    callingCodes: string[];
}
declare var google: any;

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    @ViewChild('form') targetElement: any;
    user = new User();
    addressCompany = new addressCompany();
    isLogged = false;
    showNewAddress = false;
    showBtnSave = true;
    address = new Direction_User();
    type: string;
    direction: string;
    zipCode: string;
    country: string;
    city: string;
    state: string;
    phone: string = '';
    updateWithoutPhone: boolean = false;
    changeNumber: boolean = false;
    public number_to_verify: string = '';
    public send_verification_code: boolean = false;
    public codeVerificationCheck: string = '';
    public active_verification_phone: boolean = true;

    private TWILIO_SERVICE_ENDPOINT = environment.twilio.TWILIO_SERVICE_ENDPOINT;
    private TWILIO_SERVICE_VERIFICATION_CHECK = environment.twilio.TWILIO_SERVICE_VERIFICATION_CHECK;
    private TWILIO_AUTH_TOKEN = environment.twilio.TWILIO_AUTH_TOKEN;
    private TWILIO_ACCOUNT_SID = environment.twilio.TWILIO_ACCOUNT_SID;
    private MIN_PHONE_LENGTH = 8;
    selectedCountry: string = '503';
    // countries = [
    //     {name: 'El Salvador', code: '503'},
    //     {name: 'Guatemala', code: '502'},
    //     {name: 'Nicaragua', code: '505'},
    //     {name: 'Francia', code: '33' },
    //     {name: 'Estados Unidos', code: '1'},
    //     {name: 'España', code: '34'},
    //     {name: 'Perú', code: '51'},
    //     {name: 'México', code: '52'}
    // ];
    // countryPrefix = {
    //     '503': '503',
    //     '502': '502',
    //     '505': '505',
    //     '33': '33',
    //     '1': '1',
    //     '34': '34',
    //     '51': '51',
    //     '52': '52'
    // };
    // countryMask = {
    //     '503': '00000000',
    //     '502': '0000000000',
    //     '505': '00000000',
    //     '33': '0000000000',
    //     '1': '0000000000',
    //     '34': '0000000000',
    //     '51': '0000000000',
    //     '52': '0000000000'
    // };

    countries: Country[] = [];
    countryPrefix: { [code: string]: string } = {};
    countryMask: { [code: string]: string } = {};
    code: string = '';
    latLng: any;
    map: any;
    marker: any;
    latitude: number = 0;
    longitude: number = 0;
    defaultPin = {
        'lat': 13.701444,
        'lng': -89.224422
    };
    @ViewChild('googleMap', { static: true }) mapElement: ElementRef;
    @ViewChild('search', { static: false }) serachElement: ElementRef;

    constructor(
        private router: Router,
        private auth: AuthService,
        private firestore: FirestoreService,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        private toastrService: ToastrService,
        private commonService: CommonService,
        private http: HttpClient,
        private mapsAPILoader: MapsAPILoader,
        public zone: NgZone,
    ) {
        this.auth.getUser2().then(async userLocal => {
            this.isLogged = true;
            // console.log('userLocal: ', userLocal);
            this.user = userLocal;
            // console.log('addressCompany', this.user.addressCompany);
            this.addressCompany = this.user.addressCompany == undefined ? this.addressCompany : this.user.addressCompany;
            if (userLocal.phone && userLocal.phone['nationalNumber']) {
                this.phone = (userLocal.phone && userLocal.phone['nationalNumber']) ? userLocal.phone['nationalNumber'] : '';

                let userPhone: any = this.user.phone;

                this.code = userPhone?.dialCode?.replace(/\+|\s/g, '');
            } else {
                this.getDefaultCountry();
            }

            if (this.user.direction && this.user.direction.length > 0) {
                setTimeout(() => {
                    this.user.direction.forEach((element, index) => {
                        if (element.lat_long && element.lat_long._lat && element.lat_long._long) {
                            this.loadMapGoogleDynamic(element.lat_long._lat, element.lat_long._long, 'googleMap' + index, element);
                        }
                    });
                }, 1000);
            } else {
                this.user.direction.length = 0;
                setTimeout(() => {
                    this.loadMapGoogle(this.defaultPin.lat, this.defaultPin.lng, 'googleMap');
                }, 500);
            }
            // console.log('phone', this.phone);
        }).catch(e => {
            console.log('catch ', e);
            this.router.navigate(['/pages/login']);
        });
    }

    ngOnInit(): void {
        // console.log('addressCompany', this.addressCompany);
        // let test = document.querySelector('.card-header') as HTMLElement | null;
        // console.log('test', test);
        this.getCountries();
        this.verificationPhone();
    }

    getDefaultCountry() {
        this.http.get<any>('https://ipapi.co/json/').subscribe(
            response => {
                const userCountryCode = response.country_calling_code.replace('+', '');
                this.selectedCountry = userCountryCode;
                console.log('userCountryCode', response);
                this.phone = this.countryPrefix[userCountryCode];
                this.code = userCountryCode;

                this.onCountryChange(userCountryCode);
            }
        );
    }

    setDefaultCountry() {
        // aquí puedes implementar la lógica para detectar el país por defecto del usuario, utilizando la IP o cualquier otro método
        // por ahora, simplemente establecemos El Salvador como país por defecto
        // this.selectedCountry = 'SV';
    }

    getCountries() {
        this.http.get<Country[]>('https://restcountries.com/v2/all').subscribe(
            response => {
                // console.log('countries', response);
                this.countries = response;
                this.countries.forEach(country => {
                    this.countryPrefix[country.alpha2Code] = '+' + country.callingCodes[0];
                    this.countryMask[country.alpha2Code] = '0'.repeat(country.callingCodes[0].length);
                });
                this.setDefaultCountry();
            },
            error => {
                console.log(error);
            }
        );
    }

    onCountryChange(ev) {
        // this.code = this.countryPrefix[this.selectedCountry];

        console.log("ev: ", ev)
        let data = this.countries.find(items => items.callingCodes[0] === ev);
        // console.log("data: ", data)
        
        if (data) {
            setTimeout(() => {
                let code: string = "+ " + data.callingCodes[0];
                // this.code = "+ " + data.callingCodes[0];

                if (this.user.phone === null || this.user.phone === undefined) {
                    let userPhone: any = {
                        'isoCode': (data?.alpha2Code).toLowerCase(),
                        'dialCode': code,
                        'internationalNumber': '',
                        'nationalNumber': '',
                    }
                    console.log(code);
                    
                    this.user.phone = userPhone;
                } else {
                    this.user.phone['isoCode'] = (data?.alpha2Code).toLowerCase();
                    this.user.phone['dialCode'] = code;
                    this.user.phone['internationalNumber'] = code + ' ' + (this.user.phone.nationalNumber || '');
                    // console.log("this.user.phone: ", this.user.phone)
                }
                
            }, 10);
        }
    }

    updateUser() {
        if (this.active_verification_phone) {
            this.updateWithoutPhone = this.user.verificate_phone == true;
        } else {
            if (this.user.phone === null || this.user.phone === undefined || this.changeNumber) { this.savePhoneWithoutVerification() };
        }

        // Comprobar si el nombre, apellido y número de teléfono son válidos
        // if (this.user.firstName.length >= 3 && this.user.lastName.length >= 3 && this.user.verificate_phone == true) {
        if (this.user.firstName.length >= 3 && this.user.lastName.length >= 3 && this.updateWithoutPhone == true) {
            this.showBtnSave = false;

            // Verificar si se ingresó información de dirección
            // if (this.address.state != '' && this.address.direction != '' && this.address.zipCode != '' && this.address.country != '' && this.address.city != '') {
            if (this.address.direction != '' && this.address.country != '') {
                // Agregar la dirección al arreglo de direcciones del usuario
                if (this.user.direction && this.user.direction.length > 0) {
                    this.user.direction.forEach((element, index) => {
                        this.user.direction[index].is_default = false;
                    });
                }
                this.user.direction.push({
                    direction: this.address.direction,
                    city: this.address.city,
                    country: this.address.country,
                    is_default: true,
                    // lat_long: undefined,
                    lat_long: { _lat: this.latitude, _long: this.longitude },
                    name_place: this.address.type,
                    state: this.address.state,
                    timestamp: new Date(),
                    type: this.address.type,
                    zipCode: this.address.zipCode
                });
            }

            // Actualizar la dirección de la empresa del usuario
            this.user.addressCompany = { ...this.addressCompany };

            // Mostrar una alerta de éxito
            this.commonService.alertModal('información', 1);

            // Actualizar el usuario en la base de datos
            this.service.updateUser({ ...this.user }, this.user.uid).subscribe(res => {
                this.commonService.alertModal('información', 2);
                this.showNewAddress = false;
                this.address = new Direction_User();
                this.user.direction.forEach((element, index) => {
                    if (element.lat_long && element.lat_long._lat && element.lat_long._long) {
                        this.loadMapGoogleDynamic(element.lat_long._lat, element.lat_long._long, 'googleMap' + index, element);
                    }
                });
            });

            // Mostrar el botón de guardar de nuevo
            this.showBtnSave = true;
        } else {
            // Si los campos requeridos no están llenados, mostrar un mensaje de error
            let txt = 'Los siguientes campos son requeridos: ';
            if (this.user.firstName.length < 3) txt += 'Nombre debe contener al menos 3 caracteres, ';
            if (this.user.lastName.length < 3) txt += 'Apellido debe contener al menos 3 caracteres, ';
            if (!this.user.verificate_phone) txt += 'Número de télefono validado, ';
            txt = txt.slice(0, -2); // Eliminar la última coma y el espacio
            Swal.fire('Error', txt, 'info');
        }
    }


    changeStatus(index) {
        const status = this.user.direction[index].is_default;
        const txt = !status ? 'activated' : 'disabled';

        this.user.direction.forEach((element, i) => {
            if (!status && index != i) {
                element.is_default = false;
            }
        });

        this.user.direction[index].is_default = !status;
        this.toastrService.info('Address  ' + txt);
    }

    newAddress() {
        this.showNewAddress = !this.showNewAddress;
        // let height = this.targetElement.nativeElement.offsetHeight;
        setTimeout(() => {
            this.loadMapGoogle(this.defaultPin.lat, this.defaultPin.lng, 'googleMap');
            const top = this.targetElement.nativeElement.offsetHeight;
            this.viewScroller.scrollToPosition([0, top * 2]);
        }, 500);
    }

    changePhoneNumber() {
        this.user.verificate_phone = false;
        this.phone = "";
        this.changeNumber = true;
    }

    sendVerificationCode(phone) {
        this.commonService.alertModal('');
        let numberString = phone.toString();
        // console.log('this.user.phone', numberString.replaceAll(' ', ''));
        let tel = numberString.replaceAll(' ', '');
        // console.log('tel .length', tel.length);
        let number = `+${this.code}${tel}`;

        if (tel.length < 8) {
            Swal.fire('error', 'Dear user, the number you have entered is incorrect, please verify', 'error');
        } else {
            const data = new URLSearchParams();
            data.append('To', number);
            data.append('Channel', 'sms');

            fetch(this.TWILIO_SERVICE_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${btoa(`${this.TWILIO_ACCOUNT_SID}:${this.TWILIO_AUTH_TOKEN}`)}`
                },
                body: data.toString()
            })
                .then(response => {
                    if (response.ok) {
                        Swal.fire('Success', 'El código ha sido enviado correctamente, por favor ingrese el código que ha sido enviado a su número, para poder validarlo en nuestro sistema.', 'success');
                        // console.log('response', response);
                        this.send_verification_code = true;
                        this.number_to_verify = number;
                    } else {
                        Swal.fire('Error', 'se ha producido un error al enviar el código.', 'error');
                    }

                })
                .catch(error => {
                    console.error('error', error);
                });
        }

    }

    checkVerificationCode() {
        let code = this.codeVerificationCheck.toString();

        const body = new URLSearchParams();
        body.set('To', this.number_to_verify);
        body.set('Code', code);

        fetch(this.TWILIO_SERVICE_VERIFICATION_CHECK, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${btoa(`${this.TWILIO_ACCOUNT_SID}:${this.TWILIO_AUTH_TOKEN}`)}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body.toString()
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                if (data.status == 'approved' && data.valid) {
                    let formattedNumber = this.commonService.formattedNumber(this.phone);
                    this.user.verificate_phone = true;
                    let message_post = {
                        verificate_phone: true,
                        date_verify: Date.now(),
                        user_uid_verify: this.user.uid,
                        name_user_verify: this.user.firstName,
                        verification_code: code,
                        phone: {
                            isoCode: this.user?.phone?.isoCode,
                            internationalNumber: `+${this.code}${formattedNumber}`,
                            dialCode: `+${this.code}`,
                            nationalNumber: formattedNumber
                        }
                    };

                    this.service.updateUser(message_post, this.user.uid).subscribe(response => {
                        // console.log('response update user', response);
                        this.user.verificate_phone = message_post.verificate_phone;
                        this.user.phone = message_post.phone;
                        this.user.verification_code = message_post.verification_code;
                        this.commonService.alertModal('Número de teléfono', 2);
                    });
                } else {
                    this.commonService.alertModal('Número de teléfono', 3);
                }
            })
            .catch(error => {
                console.error(error);
                this.commonService.alertModal('Número de teléfono', 3);
            });
    }

    DeleteItem(index, active) {
        if (active !== true) {
            var datos = this.user.direction;
            datos.splice(index, 1);
            this.user.direction = datos;
            // console.log("DeleteItem: ",this.user.direction);
        } else {
            Swal.fire('Info', 'You cannot delete this location because you have it selected', 'info');
        }
    }

    loadMapGoogle(lat, lng, documentID) {
        return new Promise((resolve, reject) => {
            this.latLng = new google.maps.LatLng(lat, lng);
            this.latitude = lat;
            this.longitude = lng;
            let mapOptions = {
                center: this.latLng,
                zoom: 16,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: false,
                draggable: true,
                scrollwheel: true,
            }
            let componentMap = document.getElementById(documentID);
            this.map = new google.maps.Map(componentMap, mapOptions);
            this.map.addListener('tilesloaded', () => {
            });
            this.markerOptions(true);
            resolve({ lat, lng })
        });
    }

    markerOptions(draggable) {
        this.marker = new google.maps.Marker({
            map: this.map,
            draggable: draggable,
            title: 'Drag the marker',
            animation: google.maps.Animation.DROP,
            position: this.latLng,
            icon: { url: 'https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fuploads%2Fpin.png?alt=media&token=59779625-31d7-4394-9649-08957bf56015' }
        });

        this.marker.addListener("dragend", (resMap) => {
            // console.log("resMap", resMap);
            this.searchInGeocoder(resMap.latLng.lat(), resMap.latLng.lng(), this.address);

            this.mapsAPILoader.load().then((res) => {
                // console.log("mapsAPILoader", res);
            });
        });
    }

    getPosition() {
        this.showLoading();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    var latitude = position.coords.latitude;
                    var longitude = position.coords.longitude;
                    // console.log("Latitud: " + latitude);
                    // console.log("Longitud: " + longitude);
                    var geocoder = new google.maps.Geocoder();
                    var latlng = new google.maps.LatLng(latitude, longitude);

                    await geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                // console.log(results);
                                const partnerDepartment = results[0].address_components;
                                var direccion = results[0].formatted_address;
                                // console.log("Dirección: " + direccion);
                                this.address.direction = direccion;
                                // this.address.zipCode = place.formatted_address;
                                this.address.country = partnerDepartment[partnerDepartment.length - 1].long_name;
                                this.address.city = partnerDepartment[partnerDepartment.length - 3].long_name;
                                this.address.state = partnerDepartment[partnerDepartment.length - 2].long_name;
                                // console.log(partnerDepartment[partnerDepartment.length - 2].long_name);
                            } else {
                                console.log("No se encontró ninguna dirección.");
                                Swal.fire('Info', "No se encontró ninguna dirección.", 'info');

                            }
                        } else {
                            console.log("No se pudo obtener la dirección: " + status);
                            Swal.fire('Info', "No se pudo obtener la dirección", 'info');
                        }
                    });

                    this.loadMapGoogle(latitude, longitude, 'googleMap');
                    Swal.close();
                },
                (error) => {
                    Swal.close();
                    // console.log("No se pudo obtener la ubicación: " + error.message);
                    if (error.code === error.PERMISSION_DENIED) {
                        // console.log("El usuario denegó el permiso de ubicación.");
                        Swal.fire('Error', "No se pudo obtener la ubicación: " + error.message, 'error');

                    } else {
                        // console.log("No se pudo obtener la ubicación: " + error.message);
                        Swal.fire('Error', "No se pudo obtener la ubicación: " + error.message, 'error');

                    }
                }
            );
        } else {
            // console.log("La geolocalización no es compatible con este navegador.");
            Swal.close();
            Swal.fire('Error', "La geolocalización no es compatible con este navegador.", 'error');

        }
    }

    addressChange() {
        // console.log(this.address.direction);
        if (this.address.direction != '' && this.address.direction != null && this.address.direction != undefined) {
            this.forwardGeocode()
        } else {
            this.address = new Direction_User();
        }
    }

    forwardGeocode() {
        //console.log(address);
        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.serachElement.nativeElement, {
                type: ['address'],
                componentRestrictions: { 'country': ['SV'] }
            });

            autocomplete.addListener('place_changed', () => {
                this.zone.run(() => {
                    let place = google.maps.places.PlaceResult = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    // console.log(place.formatted_address);
                    // console.log(place);
                    const partnerDepartment = place.address_components;
                    this.address.direction = place.formatted_address;
                    // this.address.zipCode = place.formatted_address;
                    this.address.country = partnerDepartment[partnerDepartment.length - 1].long_name;
                    this.address.city = partnerDepartment[partnerDepartment.length - 3].long_name;
                    this.address.state = partnerDepartment[partnerDepartment.length - 2].long_name;
                    // console.log(partnerDepartment[partnerDepartment.length - 2].long_name);
                    let latitude = place.geometry.location.lat();
                    let longitude = place.geometry.location.lng();
                    this.loadMapGoogle(latitude, longitude, 'googleMap');
                });
            });

        });
    }

    getPositionDynamic(item, index) {
        this.showLoading();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    var latitude = position.coords.latitude;
                    var longitude = position.coords.longitude;
                    // console.log("Latitud: " + latitude);
                    // console.log("Longitud: " + longitude);
                    var geocoder = new google.maps.Geocoder();
                    var latlng = new google.maps.LatLng(latitude, longitude);

                    await geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                // console.log(results);
                                const partnerDepartment = results[0].address_components;
                                var direccion = results[0].formatted_address;
                                // console.log("Dirección: " + direccion);
                                item.direction = direccion;
                                // item.zipCode = place.formatted_address;
                                item.country = partnerDepartment[partnerDepartment.length - 1].long_name;
                                item.city = partnerDepartment[partnerDepartment.length - 3].long_name;
                                item.state = partnerDepartment[partnerDepartment.length - 2].long_name;
                                item.lat_long = {
                                    _lat: latitude,
                                    _long: longitude
                                };

                                // console.log(partnerDepartment[partnerDepartment.length - 2].long_name);
                            } else {
                                console.log("No se encontró ninguna dirección.");
                                Swal.fire('Info', "No se encontró ninguna dirección.", 'info');

                            }
                        } else {
                            console.log("No se pudo obtener la dirección: " + status);
                            Swal.fire('Info', "No se pudo obtener la dirección", 'info');
                        }
                    });

                    this.loadMapGoogle(latitude, longitude, 'googleMap' + index);
                    Swal.close();
                },
                (error) => {
                    Swal.close();
                    // console.log("No se pudo obtener la ubicación: " + error.message);
                    if (error.code === error.PERMISSION_DENIED) {
                        // console.log("El usuario denegó el permiso de ubicación.");
                        Swal.fire('Error', "No se pudo obtener la ubicación: " + error.message, 'error');

                    } else {
                        // console.log("No se pudo obtener la ubicación: " + error.message);
                        Swal.fire('Error', "No se pudo obtener la ubicación: " + error.message, 'error');

                    }
                }
            );
        } else {
            // console.log("La geolocalización no es compatible con este navegador.");
            Swal.close();
            Swal.fire('Error', "La geolocalización no es compatible con este navegador.", 'error');

        }
    }

    addressChangeDynamic(item, index) {
        // console.log(this.address.direction);
        if (item.direction != '' && item.direction != null && item.direction != undefined) {
            this.forwardGeocodeDynamic(item, index)
        }
    }

    forwardGeocodeDynamic(item, documentID) {
        //console.log(address);
        this.mapsAPILoader.load().then(() => {
            let componentSearch = document.getElementById('search' + documentID);

            let autocomplete = new google.maps.places.Autocomplete(componentSearch, {
                type: ['address'],
                componentRestrictions: { 'country': ['SV'] }
            });

            autocomplete.addListener('place_changed', () => {
                this.zone.run(() => {
                    let place = google.maps.places.PlaceResult = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    // console.log(place.formatted_address);
                    // console.log(place);
                    const partnerDepartment = place.address_components;
                    item.direction = place.formatted_address;
                    // item.zipCode = place.formatted_address;
                    item.country = partnerDepartment[partnerDepartment.length - 1].long_name;
                    item.city = partnerDepartment[partnerDepartment.length - 3].long_name;
                    item.state = partnerDepartment[partnerDepartment.length - 2].long_name;
                    // console.log(partnerDepartment[partnerDepartment.length - 2].long_name);
                    let latitude = place.geometry.location.lat();
                    let longitude = place.geometry.location.lng();
                    item.lat_long = {
                        _lat: latitude,
                        _long: longitude
                    };
                    this.loadMapGoogleDynamic(latitude, longitude, 'googleMap' + documentID, item);
                });
            });

        });
    }

    loadMapGoogleDynamic(lat, lng, documentID, item) {
        return new Promise((resolve, reject) => {
            this.latLng = new google.maps.LatLng(lat, lng);
            this.latitude = lat;
            this.longitude = lng;
            let mapOptions = {
                center: this.latLng,
                zoom: 16,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: false,
                draggable: true,
                scrollwheel: true,
            }
            let componentMap = document.getElementById(documentID);
            this.map = new google.maps.Map(componentMap, mapOptions);
            this.map.addListener('tilesloaded', () => {
            });
            this.markerOptionsDynamic(true, item);
            resolve({ lat, lng })
        });
    }

    markerOptionsDynamic(draggable, item) {
        this.marker = new google.maps.Marker({
            map: this.map,
            draggable: draggable,
            title: 'Drag the marker',
            animation: google.maps.Animation.DROP,
            position: this.latLng,
            icon: { url: 'https://firebasestorage.googleapis.com/v0/b/ianos-platform-dev.appspot.com/o/assets%2Fuploads%2Fpin.png?alt=media&token=59779625-31d7-4394-9649-08957bf56015' }
        });

        this.marker.addListener("dragend", (resMap) => {
            // console.log("resMap", resMap);
            this.searchInGeocoder(resMap.latLng.lat(), resMap.latLng.lng(), item);
            this.mapsAPILoader.load().then((res) => {
            });
        });
    }

    verificationPhone() {
        this.service.sendGET_SettingsApp('General').subscribe((res) => {
            if (res.active_verification_phone != undefined) {
                this.active_verification_phone = res.active_verification_phone;
            }
        });
    }

    savePhoneWithoutVerification() {
        let formattedNumber = this.commonService.formattedNumber(this.phone);
        let message_post = {
            phone: {
                isoCode: this.user?.phone?.isoCode,
                internationalNumber: `+${this.code} ${formattedNumber}`,
                dialCode: `+${this.code}`,
                nationalNumber: formattedNumber
            }
        };
        this.service.updateUser(message_post, this.user.uid).subscribe(response => {
            // console.log('response update user', response);
            this.user.phone = message_post.phone;
            this.updateWithoutPhone = true;
            this.commonService.alertModal('Número de teléfono', 2);
        });
    }

    async searchInGeocoder(lat, lng, item) {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lat, lng);
        // console.log("latlng", {
        //     lat: lat,
        //     lng: lng,
        // });
        await geocoder.geocode({ 'latLng': latlng }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    // console.log(results);
                    const partnerDepartment = results[0].address_components;
                    var direccion = results[0].formatted_address;
                    // console.log("Dirección: " + direccion);
                    item.direction = direccion;
                    // item.zipCode = place.formatted_address;
                    item.country = partnerDepartment[partnerDepartment.length - 1].long_name;
                    item.city = partnerDepartment[partnerDepartment.length - 3].long_name;
                    item.state = partnerDepartment[partnerDepartment.length - 2].long_name;
                    item.lat_long = {
                        _lat: lat,
                        _long: lng
                    };
                    this.latitude = lat;
                    this.longitude = lng;
                    // console.log(partnerDepartment[partnerDepartment.length - 2].long_name);
                } else {
                    console.log("No se encontró ninguna dirección.");
                    Swal.fire('Info', "No se encontró ninguna dirección.", 'info');

                }
            } else {
                console.log("No se pudo obtener la dirección: " + status);
                Swal.fire('Info', "No se pudo obtener la dirección", 'info');
            }
        });
    }

    async showLoading() {
        Swal.fire({
            icon: 'info',
            title: 'Espera por favor...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timerProgressBar: true,
            timer: 6e3
        })
    }
}
