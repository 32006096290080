<app-breadcrumb [title]="'Mi cuenta'" [breadcrumb]="'Mi cuenta'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        Mi cuenta
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li *ngFor="let item of contents; let i = index;" (click)="showContent(i)" [class]="item.active ? 'active' : ''"><a href="javascript:void(0)">{{item.name}}</a></li>
                            <li (click)="signOut()" class="last"><a href="javascript:void(0)">Cerrar sesión</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="page-title">
                            <h2>Mi Cuenta</h2>
                        </div>
                        <div class="welcome-msg mb-4">
                            <p>Hola, {{user.firstName}}!</p>
                            <p>Desde el panel de Mi cuenta puede ver una instantánea de la actividad reciente de su cuenta y actualizar la información de la misma. Seleccione uno de los siguientes enlaces para ver o modificar la información.</p>
                        </div>
                        <div *ngIf="contents[0].active" class="box-account box-info">
                            <div class="box-head">
                                <h2>Información sobre la cuenta</h2>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="box">
                                        <div class="box-title">
                                            <h3>Información</h3>
                                            <a [routerLink]="'/pages/profile'">Editar</a>
                                        </div>
                                        <div class="row box-content">
                                            <div class="col text-nowrap"><h6><b>Nombres:</b> {{user.firstName}}</h6></div>
                                            <div class="col text-nowrap"><h6><b>Apellidos:</b> {{user.lastName}}</h6></div>
                                            <div class="col text-nowrap"><h6><b>Phone:</b> {{user.phone}}</h6></div>
                                            <div class="col text-nowrap"><h6><b>E-mail:</b> {{user.email}}</h6></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="box">
                                    <div class="box-title">
                                        <h3>Dirección</h3>
                                        <a [routerLink]="'/pages/profile'">Gestionar direcciones</a>
                                    </div>
                                    <div class="row">
                                        <div *ngIf="user.direction.length < 1" class="col mt-2">
                                            <address>No ha establecido una dirección de envío por defecto.</address>
                                        </div>
                                        <div *ngFor="let item of user.direction" class="col-sm-6">
                                            <h6 class="text-primary" *ngIf="item.is_default">Dirección de envío por defecto</h6>
                                            <address>
                                                <div class="col text-nowrap"><h6><b>Descripción:</b> {{item.type}}</h6></div>
                                                <div class="col text-nowrap"><h6><b>país:</b> {{item.country}}</h6></div>
                                                <div class="col text-nowrap"><h6><b>Ciudad:</b> {{item.city}}</h6></div>
                                                <div class="col text-nowrap"><h6><b>Estado/municipio:</b> {{item.state}}</h6></div>
                                                <div class="col text-nowrap"><h6><b>Dirección:</b> {{item.direction}}</h6></div>
                                                <div class="col text-nowrap"><h6><b>Zip-code:</b> {{item.zipCode}}</h6></div>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contents[1].active" class="box-account box-info">
                            <div class="page-title">
                                <h2 [textContent]="contents[1].name"></h2>
                            </div>
                            <div class="row">
                                <ng-container *ngIf="orders.length > 0">
                                    <div class="row mt-2 mb-5 p-2 border-default" *ngFor="let orderDetails of orders; let i = index;">
                                        <div class="col-lg-8">
                                            <div class="product-order">
                                                <div class="page-title">
                                                    <h2 [textContent]="'Pedido N° '+ (i+1)"></h2>
                                                </div>
                                                <div class="row product-order-detail" *ngFor="let product of orderDetails.items">
                                                    <div class="col-3">
                                                        <img [src]="product.image" [alt]="product.name" class="img-fluid">
                                                    </div>
                                                    <div class="col order_detail align-items-start">
                                                        <div>
                                                            <h4>nombre del producto</h4>
                                                            <h5>{{ product.name }}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 text-nowrap order_detail align-items-start">
                                                        <div>
                                                            <h4>cantidad</h4>
                                                            <h5>{{ product.quantity }}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 text-nowrap order_detail align-items-start">
                                                        <div>
                                                            <h4>precio</h4>
                                                            <h5>{{ product.price * product.quantity | currency:productService?.Currency.currency:'symbol' }}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="total-sec">
                                                    <ul>
                                                        <li>Subtotal <span>{{ orderDetails.amount | currency:productService?.Currency.currency:'symbol' }}</span></li>
<!--                                                        <li>Envío <span>{{ orderDetails.shipping | currency:productService?.Currency.currency:'symbol' }}</span></li>-->
                                                        <li>Impuesto <span>{{ orderDetails.tax | currency:productService?.Currency.currency:'symbol' }}</span></li>
                                                    </ul>
                                                </div>
                                                <div class="final-total">
                                                    <h3>total <span>{{ orderDetails.total | currency:productService?.Currency.currency:'symbol' }}</span></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="row order-success-sec">
                                                <div class="col-sm-12 mb-2">
                                                    <h4>summery</h4>
                                                    <ul class="order-detail">
                                                        <li><b>ID de pedido: </b> {{ orderDetails.uid }}</li>
                                                        <li><b>Fecha de pedido: </b> {{ orderDetails.created_at_formatted }}</li>
                                                        <li><b>Total del pedido: </b> {{ orderDetails.total | currency:productService?.Currency.currency:'symbol' }}</li>
                                                    </ul>
                                                </div>
                                                <!--                                                <div class="col-sm-6">-->
                                                <!--                                                    <h4>dirección de envío</h4>-->
                                                <!--                                                    <ul class="order-detail">-->
                                                <!--                                                        <li>{{ orderDetails.shippingDetails.address }}</li>-->
                                                <!--                                                        <li>{{ orderDetails.shippingDetails.state }}</li>-->
                                                <!--                                                        <li>{{ orderDetails.shippingDetails.country }}, {{ orderDetails.shippingDetails.postalcode }}</li>-->
                                                <!--                                                        <li>Contact No. {{ orderDetails.shippingDetails.phone }}</li>-->
                                                <!--                                                    </ul>-->
                                                <!--                                                </div>-->

                                                <div class="col-sm-12 payment-mode">
                                                    <h4>método de pago</h4>
                                                    <p [textContent]="orderDetails.waytopay"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="orders.length < 1" class="col mt-2">
                                    <address>No hay pedidos <br>
                                        Haz un pedido para hacerme feliz :)</address>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contents[2].active" class="box-account box-info">
                            <div class="box-head">
                                <h2>{{ contents[2].name }}</h2>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="box">
                                        <div class="box-title">
                                            <h3>Información</h3>
                                            <a [routerLink]="'/pages/profile'">Editar</a>
                                        </div>
                                        <div class="row box-content">
                                            <div class="col text-nowrap text-lowercase"><h6><b>nit:</b> {{nit}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>nrc:</b> {{nrc}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>teléfono:</b> {{phone}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>dirección:</b> {{address}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>departamento:</b> {{department}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>municipio:</b> {{municipality}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>Razón social:</b> {{socialReason}}</h6></div>
                                            <div class="col text-nowrap text-lowercase"><h6><b>nombre comercial:</b> {{nameCommercial}}</h6></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->
