import { Component, OnInit, Input } from '@angular/core';
import { CollectionSlider } from '../../../shared/data/slider';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

  @Input() categories: any[];
  @Input() category: string;
  @Input() class: string;
  width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  constructor() { }

  ngOnInit(): void {
  }

   public CollectionSliderConfig: any = CollectionSlider;

  countCharacter(string) {
    let limit = (this.width < 1560) ? 25 : 45;
    if (string.length > limit) string = string.substring(0, limit) + '...';
    return string;
  }
}
