<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border" style="font-size: 14px; text-align: left;">{{ title }}</h5>
    <owl-carousel-o *ngIf="products.length > 0" [options]="NewProductSliderConfig" class="offer-slider">
        <ng-template carouselSlide id="slide-1">
            <div>
                <div class="d-flex align-items-center" *ngFor="let product of products | slice:0:3 ">
                    <div class="row">
                        <div class="col-12">
                            <a [href]="['products'+product?.url_product]"
                                class="d-flex align-items-center justify-content-center">
                                <img class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
                                    [lazyLoad]="product.images[0].src" alt="">
                            </a>
                        </div>
                        <div class="col-12">
                            <div class="media-body align-self-center">
                                <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                                <a [href]="['products'+product?.url_product]">
                                    <div class="lable-block" *ngIf="product?.discount && product?.product_promote">
                                        <span class="lable3"> {{ (product?.discount).toFixed(0) }}% </span>
                                    </div>
                                    <h6>{{ product.title | titlecase }}</h6>
                                </a>
                                <h4>
                                    {{ product?.price * productService.Currency?.price | discount:product |
                                    currency:productService.Currency?.currency:'symbol' }}
                                    <del *ngIf="product?.discount">
                                        <span class="money">
                                            {{ product?.price * productService.Currency?.price |
                                            currency:productService.Currency?.currency:'symbol' }}
                                        </span>
                                    </del>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide id="slide-2">
            <div>
                <div class="d-flex align-items-center" *ngFor="let product of products | slice:3:6 ">
                    <div class="row">
                        <div class="col-12">
                            <a [href]="['products'+product?.url_product]"
                                class="d-flex align-items-center justify-content-center">
                                <img class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
                                    [lazyLoad]="product.images[0].src" alt="">
                            </a>
                        </div>
                        <div class="col-12">
                            <div class="media-body align-self-center">
                                <a [href]="['products'+product?.url_product]">
                                    <h6>{{ product.title | titlecase }}</h6>
                                </a>
                                <h4>
                                    {{ product?.price * productService.Currency?.price | discount:product |
                                    currency:productService.Currency?.currency:'symbol' }}
                                    <del *ngIf="product?.discount">
                                        <span class="money">
                                            {{ product?.price * productService.Currency?.price |
                                            currency:productService.Currency?.currency:'symbol' }}
                                        </span>
                                    </del>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->