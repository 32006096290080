import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {HttpService} from "../services/http.service";
import Swal from "sweetalert2";
import {ViewportScroller} from "@angular/common";
import {environment} from "../../environments/environment";
import {ProductService} from "../shared/services/product.service";
import {FirestoreService} from "../services/firestore.service";

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
    public themeLogo: string = 'https://firebasestorage.googleapis.com/v0/b/ch-tienda-online-app-dev.appspot.com/o/assets%2Fimages%2Flogos%2Fyourlogo.png?alt=media&token=1e815d03-1b4c-47a0-9f8e-55ba3ee7d92c';
    public url: any;
    public title: string = '';
    public sendBreadcrumbDynamic: any [] = [];
    @ViewChild('boxSearch') targetElement: any;
    productsSearch: any[] = [];
    resultSearch: any[] = [];
    userSearch: boolean = false;


    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private viewScroller: ViewportScroller,
        private service: HttpService,
        private firestore: FirestoreService,
        public productService: ProductService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
            this.url = this.router.url;
            this.title = this.url.substring(this.url.lastIndexOf('/') + 1);
            this.url = this.url.split('/');
            this.sendBreadcrumbDynamic = [];
            this.url = this.router.url;
            let arrayBreadcrumb = this.url.split('/');
            this.sendBreadcrumbDynamic = environment.breadcrumbDynamic(arrayBreadcrumb);
            this.title.replaceAll('-', ' ');
        });
        this.service.sendGET_SettingsApp('Store').subscribe((res) =>   {
            this.themeLogo = res.header.logo.default;
        });
        // this.getProductsForSearch();
    }

    ngOnInit(): void {
        console.log('title', this.title);
        const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const WEEKS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        /*this.service.getOrders().subscribe( res => {
            console.log('response getOrders', res);
            res.forEach( element => {
                let now = new Date(element.created_at_formatted);
                element.created_at_formatted = WEEKS[now.getDay()] + ", " + now.getDate() + " of " + MONTHS[now.getMonth()] + " of " + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes();
                this.service.saveOrder(element).then( then => {
                    console.log('save order', then);
                }).catch( err => {
                    console.log('err save order', err);
                })
            })
        })*/


        /*this.service.getOrders().subscribe(res => {
            console.log('response getUsers', res);
            res.forEach( element => {
                let user = {
                    "ID_usuario"    : element.ID_usuario,
                    "firstName"     : element.firstName,
                    "lastName"      : element.lastName,
                    "email"         : element.email,
                    "password"      : element.password,
                    "telefono"      : element.telefono,
                    "ciudad"        : element.ciudad,
                    "postalcode"    : element.postalcode,
                    "country"       : element.country,
                    "deparment"     : element.deparment,
                    "tipo"          : element.tipo,
                    "is_super"      : element.is_super,
                }

                this.firestore.save('UsersPHP', user).then(response => {
                    console.log('save userLocal ', response);
                }).catch(error => {
                    console.log('error save new user', error);
                })

            })
        })*/


    }

    filter(event) {
        this.resultSearch = this.productsSearch.filter(item => item.title.toLowerCase().indexOf(event.toLowerCase()) > -1);
        Swal.fire('Exito', 'Búsqueda con éxito', 'success');
        this.userSearch = true;
        let top = this.targetElement.nativeElement.offsetTop;
        console.log('top ', top)
        this.viewScroller.scrollToPosition([0, top]);
    }

    // getProductsForSearch() {
    //     this.firestore.getWhere('Products', 'product_active', true).subscribe(res => {
    //         res.forEach(element => {
    //             if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0){
    //                 let img = {
    //                     "src": '',
    //                     "alt": element.product_name,
    //                 }
    //                 element.title   = element.product_name;
    //                 element.description = element.product_description || element.specifications;
    //                 element.brand   = element.marca;
    //                 element.sale    = element.product_active;
    //                 element.price   = element.product_price;
    //                 element.new     = false;
    //                 if (element.product_promote) element.discount = element.product_discount || element.discount;
    //                 element.stock   = element.product_stock || element.products_stock;
    //                 element.images  = [];
    //                 if (element.extra_images != null) {
    //                     element.extra_images.forEach(index => {
    //                         img.src = index;
    //                         element.images.push(img);
    //                     })
    //                 }
    //                 img.src = element.product_imagesm;
    //                 element.images.push(img);
    //                 this.productsSearch.push(element);
    //             }
    //         });
    //     })
    // }


}
