import {Injectable} from '@angular/core';
import {WindowRef} from './window-ref.service';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {

    constructor(
        private windowRef: WindowRef
    ) {
    }

    getBrowserName(): string {
        const userAgent = this.windowRef.nativeWindow.navigator.userAgent;
        let browserName: string;

        // Detecta el nombre del navegador según el usuario agent
        if (userAgent.indexOf('Chrome') > -1) {
            browserName = 'Google Chrome';
        } else if (userAgent.indexOf('Safari') > -1) {
            browserName = 'Apple Safari';
        } else if (userAgent.indexOf('Firefox') > -1) {
            browserName = 'Mozilla Firefox';
        } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
            browserName = 'Microsoft Internet Explorer';
        } else if (userAgent.indexOf('Edge') > -1) {
            browserName = 'Microsoft Edge';
        } else {
            browserName = 'unknown';
        }

        return browserName;
    }
}
