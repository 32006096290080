import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {addressCompany, User} from '../../../shared/classes/user';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {HttpService} from '../../../services/http.service';
import {ProductService} from '../../../shared/services/product.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public openDashboard: boolean = false;
    user = new User();
    isLogged: boolean = false;
    public contents = [
        {
            name: 'Información de la cuenta',
            active: true,
        },
        {
            name: 'Mis pedidos',
            active: false,
        },
        {
            name: 'Datos de la empresa',
            active: false,
        },
    ];
    orders: any [] = [];
    nit: string = '';
    nrc: string = '';
    phone: number;
    address: string = '';
    department: string = '';
    municipality: string = '';
    socialReason: string = '';
    nameCommercial: string = '';

    constructor(
        private auth: AuthService,
        private router: Router,
        private toastrService: ToastrService,
        private service: HttpService,
        public productService: ProductService,
    ) {

    }

    ngOnInit(): void {
        this.getUser();
    }

    ngAfterViewInit(): void {

    }


    ToggleDashboard() {
        this.openDashboard = !this.openDashboard;
    }

    showContent(index) {
        this.contents.forEach((element, i) => element.active = i == index);
    }

    signOut() {
        this.toastrService.info('logging out, please wait');
        this.auth.logout().then((e) => {
            this.isLogged = false;
            window.location.href = '/';
        }).catch((e) => {
            console.log('catch signOut', e);
        });
    }

    getUser(){
        this.auth.getUser2().then(userLocal => {
            this.isLogged = true;
            console.log('userLocal',userLocal);
            this.setDataUser(userLocal);
            // this.user = userLocal;
            this.nit = userLocal.addressCompany?.nit || '';
            this.nrc = userLocal.addressCompany?.nrc || '';
            this.phone = userLocal.addressCompany?.phone || 0;
            this.address = userLocal.addressCompany?.address || '';
            this.department = userLocal.addressCompany?.department || '';
            this.municipality = userLocal.addressCompany?.municipality || '';
            this.socialReason = userLocal.addressCompany?.socialReason || '';
            this.nameCommercial = userLocal.addressCompany?.nameCommercial || '';
        }).catch(e => {
            console.log('catch ', e);
            // this.router.navigate(['/pages/login']);
        });
    }

    setDataUser(data){
        this.user.uid = data?.uid;
        this.user.username = data?.username || '';
        this.user.firstName = data?.firstName || '';
        this.user.lastName = data?.lastName || '';
        this.user.email = data?.email || '';
        this.user.phone = (data.phone && data.phone['nationalNumber']) || '';
        if (data && Array.isArray(data.direction)) {
            this.user.direction = data.direction;
        } else {
            this.user.direction = [];
        }
        this.getOrders();
    }
    getOrders() {
        this.service.getOrdersByUser(this.user.uid, 1e3).subscribe(res => {
            // console.log('response orders by B', res);
            if (Array.isArray(res) && res.length > 0) {
                this.orders = res.sort((a, b) => {
                    if (a.order_createday._nanoseconds < b.order_createday._nanoseconds) {
                        return -1;
                    } else if (a.order_createday._nanoseconds > b.order_createday._nanoseconds) {
                        return 1;
                    }
                    return 0;
                });
            }
        });
    }
}
