import {Component, Input} from '@angular/core';
import {inputNames} from "@angular/cdk/schematics";

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
    @Input() item = [];

    constructor() {
    }
}
