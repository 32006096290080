<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>create account</h3>
                <div class="theme-card">
                    <form class="theme-form" autocomplete="off">
                        <!--<div class="row">
                            <div class="col-md-6">
                                <label for="email">First Name</label>
                                <input type="text" class="form-control" id="fname" placeholder="First Name" required="">
                            </div>
                            <div class="col-md-6">
                                <label for="review">Last Name</label>
                                <input type="password" class="form-control" id="lname" placeholder="Last Name" required="">
                            </div>
                        </div>-->
                        <div class="row check-out">
                            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                <div class="field-label mb-2">Correo electrónico</div>
                                <input type="text" name="email" value="" placeholder=""
                                    [formControl]="checkoutForm.controls['email']" class="form-control"
                                    autocomplete="off">
                                <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.required"
                                    class="text text-danger">
                                    Correo electrónico obligatorio
                                </div>
                                <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.email"
                                    class="text text-danger">
                                    Correo electrónico no válido
                                </div>
                            </div>

                            <div class="form-group col-md-6 col-sm-6 col-xs-6">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <div class="field-label mb-2">Contraseña</div>
                                        <div class="input-group mb-2">
                                            <input [type]="typePassword.password1" name="password"
                                                class="form-control mb-0"
                                                [formControl]="checkoutForm.controls['password']" value=""
                                                placeholder="" autocomplete="off">
                                            <div class="input-group-text cursor-pointer" (click)="viewPassword(1)">
                                                <i aria-hidden="true" class="fa fa-eye"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <div class="field-label mb-2">Verificar Contraseña</div>
                                        <div class="input-group mb-2">
                                            <input [type]="typePassword.password2" name="password2"
                                                class="form-control mb-0"
                                                [formControl]="checkoutForm.controls['password2']" value=""
                                                placeholder="" autocomplete="off">
                                            <div class="input-group-text cursor-pointer" (click)="viewPassword(2)">
                                                <i aria-hidden="true" class="fa fa-eye"></i>
                                            </div>
                                        </div>
                                        <div *ngIf="checkoutForm.controls.password.touched && checkoutForm.controls.password.errors?.required"
                                            class="mb-2 text text-danger">
                                            * Contraseña es requerida
                                        </div>
                                        <div *ngIf="checkoutForm.controls.password.touched && checkoutForm.controls.password.errors?.minlength"
                                            class="mb-2 text text-danger">
                                            * Mínimo 6 caracteres
                                        </div>
                                        <div *ngIf="checkoutForm.controls.password.touched && checkoutForm.controls.password.errors?.pattern"
                                            class="mb-2 text text-danger">
                                            * La contraseña debe contener al menos una letra mayúscula, un número y un
                                            carácter especial. Eje: Password123@
                                        </div>
                                        <div *ngIf="checkoutForm.controls.password2.touched && checkoutForm.value.password2 !== checkoutForm.value.password"
                                            class="mb-2 text text-danger">
                                            * Las contraseñas no coinciden
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button [disabled]="!checkoutForm.valid || checkoutForm.value.password2 !== checkoutForm.value.password" (click)="createAccount()" class="btn btn-solid">Crear
                            Cuenta</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->