<ul class="product-social">
    <li>
      <a href="https://www.facebook.com/">
        <i class="fa fa-facebook"></i>
      </a>
    </li>
    <li>
      <a href="https://plus.google.com/discover">
        <i class="fa fa-google-plus"></i>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/">
        <i class="fa fa-twitter"></i>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/">
        <i class="fa fa-instagram"></i>
      </a>
    </li>
</ul>