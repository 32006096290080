<app-breadcrumb [title]="'Forget Password'" [breadcrumb]="'Forget Password'"></app-breadcrumb>
<!-- section start -->
<section class="pwd-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <h2>Forget Your Password</h2>
                <form class="theme-form">
                    <div class="row">
                        <div class="col-md-12">
                            <input type="text" class="form-control" id="email" placeholder="Enter Your Email" required="">
                        </div>
                    </div>
                    <button class="btn btn-solid">Submit</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->