import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  public currentMoneySubject:   Subject<string>   = new Subject<string>();
  public currentTaxSubject:     Subject<number>   = new Subject<number>();
  public include_service_fee:   Subject<boolean>  = new Subject<boolean>();
  public include_tax:           Subject<boolean>  = new Subject<boolean>();
  public view_tax:              Subject<boolean>  = new Subject<boolean>();
  public service_fee_limit:     Subject<number>   = new Subject<number>();
  public service_fee_percent:   Subject<number>   = new Subject<number>();
  public unique_tax:            Subject<boolean>   = new Subject<boolean>();
  public isWallet:              Subject<boolean>   = new Subject<boolean>();

  public is_super:              Subject<boolean>  = new Subject<boolean>();
  public uid:                   Subject<string>   = new Subject<string>();
  public appName:               Subject<string>   = new Subject<string>();
  public primary_color:         Subject<string>   = new Subject<string>();
  public secondary_color:       Subject<string>   = new Subject<string>();
  public phoneWhatsapp:         Subject<number>   = new Subject<number>();
  public logoWhatsapp:          Subject<string>   = new Subject<string>();
  public transactions:          Subject<any>      = new Subject<any>();
  public currency:              Subject<Map<string, any>> = new Subject<Map<string, any>>();

  constructor() {
  }
}
