<!-- Start Subcategories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">{{ title }}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let item of subcategories">
                    <a *ngIf="!href" class="" [routerLink]="[item.url]"> {{ item.name }} </a>
                    <a *ngIf="href"  class="" [href]="['/products/'+item.url]"> {{ item.name }} </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Subcategories List -->
