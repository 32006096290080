<app-header-one [themeLogo]="themeLogo"></app-header-one>

<!-- Home slider start-->
<section class="p-0">
  <app-slider 
    [sliders]="sliders" 
    [textClass]="'text-center'"
    [category]="'furniture'"
    [buttonText]="'shop now'"> 
  </app-slider>
</section>
<!-- Home slider End -->

<!-- collection banner -->
<section class="banner-padding banner-furniture">
    <div class="container-fluid">
        <div class="row partition3">
            <div class="col-md-4" *ngFor="let collection of collections">
                <a [routerLink]="['/shop/collection/left/sidebar']" 
                   [queryParams]="{ category: 'furniture'}">
                    <div class="collection-banner p-right text-end">
                        <img [src]="collection.image" alt="collection" class="img-fluid">
                        <div class="contain-banner banner-3">
                            <div>
                                <h4>{{collection.save}}</h4>
                                <h2>{{collection.title}}</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- collection banner end -->

<!-- Tab product start-->
<div class="title1 section-t-space">
  <h4>exclusive products</h4>
  <h2 class="title-inner1">special products</h2>
</div>
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills" >
          <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
            <a ngbNavLink>
               {{ collection }}
            </a>
            <ng-template ngbNavContent>
              <div class="no-slider row">
                  <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                      <div class="product-box">
                          <app-product-box-one [product]="product" [currency]="productService?.Currency">
                          </app-product-box-one>
                      </div>
                  </ng-container>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->

<!-- Parallax banner -->
<section class="p-0">
    <div class="full-banner parallax-banner3 parallax text-center p-left"
        [ngStyle]="{'background-image': 'url(assets/images/parallax/5.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="banner-contain">
                        <h2>2018</h2>
                        <h3>interior design in home</h3>
                        <h4>special offer</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Parallax banner end -->

<!-- blog section start-->
<section class="blog p-t-0 section-b-space">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- Cart Open To Top Side-->
<app-cart-variation [direction]="'top'"></app-cart-variation> 
<app-footer-one [themeLogo]="themeLogo"></app-footer-one>