import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeSlider, ProductSlider } from '../../shared/data/slider';
import { Product } from '../../shared/classes/product';
import { ProductService } from '../../shared/services/product.service';
import { FirestoreService } from "../../services/firestore.service";
import { HttpService } from "../../services/http.service";
import { element } from "protractor";
import Swal from "sweetalert2";
import { ViewportScroller } from "@angular/common";
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/classes/user';
import { NewsletterComponent } from '../../shared/components/modal/newsletter/newsletter.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit, OnDestroy {

    @ViewChild("newsletter", { static: false }) NewsLetter: TemplateRef<any>;

    public themeLogo: string = 'https://firebasestorage.googleapis.com/v0/b/ch-tienda-online-app-dev.appspot.com/o/assets%2Fimages%2Flogos%2Fyourlogo.png?alt=media&token=1e815d03-1b4c-47a0-9f8e-55ba3ee7d92c';
    public bannerParallax: string = 'assets/images/parallax/8.jpg';
    @ViewChild('boxSearch') targetElement: any;
    public products: Product[] = [];
    public products2: Product[] = [];
    public newProducts: Product[] = [];
    public productCollections: any[] = [];
    public active;
    tabs = [1, 2, 3, 4, 5];
    homeSlider: any[] = [];

    titleWelcome: string = '';
    messageWelcome: string = '';
    category: any[] = [];
    subcategory: any[] = [];
    clasificaciones: any[] = [];
    allProducts: any[] = [];
    brands: any[] = [];
    data = {
        "total_docs_by_collection": 8e3,
        "num_page": 0,
        "limit": 500,
        "offset": 500, // tiene que se el mismo que el limit
        "order_by": 'product_createday._seconds',
        "order": 'desc',
    };
    result: string = '';
    productsSearch: any[] = [];
    resultSearch: any[] = [];
    userSearch: boolean = false;

    public HomeSliderConfig: any = HomeSlider;
    public ProductSliderConfig: any = ProductSlider;

    // services
    public categories = [];

    // Logo
    public logos = [{
        image: 'assets/images/logos/1.png',
    }, {
        image: 'assets/images/logos/2.png',
    }, {
        image: 'assets/images/logos/3.png',
    }, {
        image: 'assets/images/logos/4.png',
    }, {
        image: 'assets/images/logos/5.png',
    }, {
        image: 'assets/images/logos/6.png',
    }, {
        image: 'assets/images/logos/7.png',
    }, {
        image: 'assets/images/logos/8.png',
    }];
    newsLetters: any[] = [];
    user = new User();
    public closeResult: string;
    dataNewsLetter: any = {};

    constructor(
        private _sanitizer: DomSanitizer,
        private firestore: FirestoreService,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        public productService: ProductService,
        private modalService: NgbModal,
        private auth: AuthService,
    ) {
        this.service.getOneSlider('x77FwNyhdEvmFv5fIWEv').subscribe((res) => {
            this.homeSlider = res.images;
            // console.log('slider', this.homeSlider);
        });

        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            // console.table(res);
            this.titleWelcome = res.titleWelcome;
            this.messageWelcome = res.messageWelcome;
            this.bannerParallax = res.bannerParallax;
            this.themeLogo = res.header.logo.default;
        });

        this.service.getCategories().subscribe((res) => {
            this.categories = [];
            res.forEach(item => {
                if (item.category_active) {
                    item.image = item.category_image_sm.replace('https://centralhidraulica.', 'https://store.centralhidraulica.');
                    item.title = item.category_name;
                    item.text = this._sanitizer.bypassSecurityTrustHtml(`<li>${item.category_description}</li>`);
                    this.categories.push(item);
                }
            })

        });

        let data = {
            "total_docs_by_collection": 14,
            "num_page": 1,
            "limit": 14,
            "offset": 0,
            "order_by": 'product_name',
            "order": 'asc',
        }
        this.service.getProductsByOrder(data).subscribe(res => {
            if (res.products) {
                this.products = [];
                this.products = this.productService.listProducts(res);
            }
            // this.getCollectionProducts('data');
            // console.log('products', this.products);
        });




        data.total_docs_by_collection = 100;
        data.num_page = 1;
        data.offset = 0;
        data.order_by = 'doc_created_date';
        data.order = 'desc';
        this.service.getProductsByOrder(data).subscribe(res => {
            if (res.products) {
                this.newProducts = [];
                this.newProducts = this.productService.listProducts(res, false);
            }
        });

        /* data.order_by = 'counter_view_modal';
         this.service.getProductsByOrder(data).subscribe( res => {
             this.productCollections = [];
             res.products.forEach( element => {
                 if (element.product_available){
                     let img = {
                         "src": '',
                         "alt": element.product_name,
                     }
                     element.title           = element.product_name;
                     element.description     = element.specifications;
                     element.brand           = element.marca;
                     element.sale            = element.product_active;
                     element.price           = element.product_price;
                     element.discount        = element.product_discount;
                     element.stock           = element.product_stock;
                     element.images          = [];
                     element.new             = true;
                     if (element.extra_images != null) {
                         element.extra_images.forEach(index => {
                             img.src = index;
                             element.images.push(img);
                         })
                     }
                     img.src = element.product_imagesm;
                     element.images.push(img);
                     this.products.push(element);
                     this.productCollections.push(element);
                 }
             });
             console.table(res.products)
         });*/

        /*this.service.sendGET_brand('brand_name', 'asc').subscribe(res => {
            this.brands = res.brands;
            console.log('sendGET_brand', this.brands);
        })*/

        this.getProducts();

        this.loadNewsletterData();

        this.auth.getUser2().then(async userLocal => {
            // console.log("res: ",userLocal);
            if (userLocal) {
                this.user = userLocal;
            }
        }).catch(async (e) => { })
    }


    ngOnInit(): void {
        // Add class in body
        document.body.classList.add("tools-bg");
        // this.getClassifications();
        // this.getCategories();
        this.getSubategories();
        // this.getAllProducts().then( r => {
        //     console.log('then function', r);
        // });
        // this.getProductsForSearch();
    }

    loadNewsletterData() {
        this.firestore.getWhere("Newsletter", "newsletter_active", true).subscribe(res => {
            if (res) {
                // console.log("res", res);
                res.sort(function (a, b) {
                    if (a.newsletter_order > b.newsletter_order) {
                        return 1;
                    }
                    if (a.newsletter_order < b.newsletter_order) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });

                this.newsLetters = res;
            }
        });
    }

    async getAllProducts() {
        await this.service.get_products().subscribe(res => {
            this.allProducts = res;
            // console.log('getAllProducts', res);
        })
    }

    async getCategories() {
        this.category = [];
        await this.service.sendGET_Category().subscribe((res) => {
            for (let i = 0; i < res.length; i++) {
                this.category.push({
                    id: res[i].id,
                    category_name: res[i].category_name,
                    ID_categoria: res[i].category_idNR,
                });
            }
        });
        // console.log('categories', this.category)
    }

    getSubategories() {
        this.subcategory = [];
        let orderBy = 'asc';
        let orderField = 'subcategory_order';
        this.service.sendGET_subcategory(orderField, orderBy).subscribe((res) => {
            res.subcategories.forEach(element => {
                /*this.subcategory.push({
                    id: element.id,
                    ID_subcategoria: element.ID_subcategoria,
                    subcategory_name: element.subcategory_name,
                });*/
                this.subcategory.push(element);
            });
        });
        // console.log('subcategories 2', this.subcategory)
    }

    async getClassifications() {
        this.clasificaciones = [];
        let orderBy = 'asc';
        let orderField = 'classification_order';
        await this.service.sendGET_Classifications(orderField, orderBy).subscribe((res) => {
            // console.log('Classifications ', res)
            res.classifications.forEach(element => {
                this.clasificaciones.push({
                    id: element.id,
                    ID_clasificacion: element.ID_clasificacion,
                    ID_subcategoria: element.ID_subcategoria,
                    ID_categoria: element.ID_categoria,
                    classification_name: element.classification_name,
                });
            });
        });
        // console.log('classifications 2', this.clasificaciones)
    }

    ngOnDestroy(): void {
        // Remove class in body
        document.body.classList.remove("tools-bg");
    }

    // Product Tab collection

    getCollectionProducts(collection) {
        let data = this.products.filter((item) => {
            if (item.stock > 1) return item;
        })
        // console.log('products', data);
        return data;
    }

    migrateProducts() {
        /*let i = 1;
        this.allProducts.forEach( element => {
            let message_post = element;

            this.category.forEach(itemCategory => {
                if (element.ID_categoria_codigo == itemCategory.ID_categoria) message_post.category_uid = itemCategory.id;
            })

            this.subcategory.forEach(itemSubcategory => {
                if (element.ID_subcategoria_codigo == itemSubcategory.ID_subcategoria) message_post.subcategory_uid = itemSubcategory.id;
            })

            this.clasificaciones.forEach(itemClasificacion => {
                if (element.ID_clasificacion == itemClasificacion.ID_clasificacion) message_post.classification_uid = itemClasificacion.id;
            })

            console.log('prducto '+i,element);

            this.service.sendPOST_Product(message_post).then(res => {
                console.log('guardo', element);
            });

            i++;
        })

        console.log('todos', this.allProducts);*/
    }

    saveStorage() {
        // this.firestore.save('Settings', this.store).then( then => {
        //     console.log('save settings store', then)
        // }).catch( catchError => {
        //     console.error('error to save settings store', catchError)
        // })
    }

    updateCategories() {
        // return false;
        this.products2.forEach(element => {
            /*
                        // this.category.forEach(itemCategory => {
                        //     if (element.ID_categoria_codigo == itemCategory.ID_categoria) element.category_uid = itemCategory.id;
                        // })

                        // this.subcategory.forEach( itemSubcategory => {
                        //     if (element.id_subcategory == itemSubcategory.ID_subcategoria) element.subcategory_uid = itemSubcategory.id;
                        // })
                        //
                        // element.images?.forEach( images => {
                        //     images.src = images.src.replace('https://newrolling.com','https://old.newrolling.com');
                        // })

                        /!*element.extra_images?.forEach( img => {
                            if (img.url_image != undefined) img.url_image = img.url_image.replace('https://newrolling.com','https://old.newrolling.com');
                        })*!/

                        // element.product_imagesm     = element.product_imagesm.replace('https://newrolling.com','https://old.newrolling.com');
                        // element.product_imagebig1   = element.product_imagebig1.replace('https://newrolling.com','https://old.newrolling.com');
                        // element.product_imagebig2   = element.product_imagebig2.replace('https://newrolling.com','https://old.newrolling.com');

                        // this.brands.forEach( item => {
                        //     if (element.brand_name == item.brand_name) element.brand_uid = item.id;
                        // })



                        if (element.extra_images != null) {
                            let img = [];
                            element.extra_images.forEach(index => {
                                let imgExtra = {
                                    "src": index,
                                    "alt": element.product_name,
                                }
                                img.push(imgExtra);
                            })
                            element.extra_images = img;
                        }
            */
            element.discount = 50;
            element.id_discount = 5;
        })
        // console.log('this.products2', this.products2);
        // return false;
        // setTimeout(() => {
        //     // this.products2.forEach( element => {
        //     this.products2.forEach(element => {
        //         this.service.updateProduct(element, element.id).then(then => {
        //           // console.log('then update', then);
        //         }).catch(err => {
        //           // console.error('error update', err);
        //         })
        //     })
        // }, 1e3);

    }

    getProducts() {
        this.products2 = [];
        let data = {
            "total_docs_by_collection": 14,
            "num_page": 1,
            "limit": 28,
            "offset": 0,
            "order_by": 'doc_created_date',
            "order": 'asc',
        }
        this.service.getProductsByOrder(data).subscribe(res => {
            if (res.products) {
                this.products2 = this.productService.listProducts(res, false);
            }
        })
    }

    filter(event) {
        this.resultSearch = this.productsSearch.filter(item => item.title.toLowerCase().indexOf(event.toLowerCase()) > -1);
        Swal.fire('Exito', 'Búsqueda con éxito', 'success');
        this.userSearch = true;
        let top = this.targetElement.nativeElement.offsetHeight;
        // console.log('top ', top)
        this.viewScroller.scrollToPosition([0, top]);
    }

    timeConverter(UNIX_timestamp) {
        var time;
        time = new Date(UNIX_timestamp.seconds * 1000 + UNIX_timestamp.nanoseconds / 1000000);
        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var date = time.toLocaleDateString('en-US', options) + " - " + time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        if (time.toLocaleDateString('en-US', options) === "Invalid Date") {
            return "Sin Datos";
        } else {
            return date;
        }
    }

    NewsLetterModal(news) {
        this.dataNewsLetter = news;
        this.modalService.open(this.NewsLetter, {
            size: 'lg',
            ariaLabelledBy: 'NewsLetter-Modal',
            centered: true,
            windowClass: 'newsletterm NewsLetterModal'
        }).result.then((result) => {
            `Result ${result}`
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    /*getProductsForSearch() {
        this.firestore.getWhere('Products', 'product_active', true).subscribe(res => {
            res.forEach(element => {
                if (element.product_available && element.product_active && element.products_stock > 0 && element.price_tax_product > 0){
                    let img = {
                        "src": '',
                        "alt": element.product_name,
                    }
                    element.title = element.product_name;
                    element.description = element.product_description || element.specifications;
                    element.brand = element.marca;
                    element.sale = element.product_active;
                    element.price = element.product_price;
                    element.new = false;
                    element.discount = element.product_discount || element.discount;
                    element.stock = element.product_stock || element.products_stock;
                    element.images = [];
                    if (element.extra_images != null) {
                        element.extra_images.forEach(index => {
                            img.src = index;
                            element.images.push(img);
                        })
                    }
                    img.src = element.product_imagesm;
                    element.images.push(img);
                    this.productsSearch.push(element);
                }
            });
        })
    }*/
}
