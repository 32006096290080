<app-breadcrumb [title]="'Elements'" [breadcrumb]="'Collection Banner'"></app-breadcrumb>

<!-- two banner -->
<section class="pb-0">
    <div class="container">
        <div class="row partition2">
            <div class="col-md-6">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
                    <div class="collection-banner p-right text-center">
                        <div class="img-part">
                            <img src="assets/images/collection/fashion/1.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="contain-banner">
                            <div>
                                <h4>save 30%</h4>
                                <h2>men</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
                    <div class="collection-banner p-right text-center">
                        <div class="img-part">
                            <img src="assets/images/collection/fashion/2.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="contain-banner">
                            <div>
                                <h4>save 60%</h4>
                                <h2>women</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- two banner -->

<!-- three banner -->
<section class="banner-goggles ratio2_1">
    <div class="container">
        <div class="row partition3">
            <div class="col-md-4">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'electronics'}">
                    <div class="collection-banner p-right text-end">
                        <div class="img-part">
                            <img src="assets/images/collection/electronics/1.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="contain-banner banner-3">
                            <div>
                                <h4>10% off</h4>
                                <h2>speaker</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'electronics'}">
                    <div class="collection-banner p-right text-end">
                        <div class="img-part">
                            <img src="assets/images/collection/electronics/2.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="contain-banner banner-3">
                            <div>
                                <h4>10% off</h4>
                                <h2>earplug</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'electronics'}">
                    <div class="collection-banner p-right text-end">
                        <div class="img-part">
                            <img src="assets/images/collection/electronics/3.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="contain-banner banner-3">
                            <div>
                                <h4>50% off</h4>
                                <h2>best deal</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- three banner -->

<!-- four banner -->
<section class="ratio2_1">
    <div class="container">
        <div class="row partition4">
            <div class="col-lg-3 col-md-6">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/watch/1.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h4>save 30%</h4>
                                <h2>men</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-3 col-md-6">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/watch/3.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h4>save 60%</h4>
                                <h2>women</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-3 col-md-6">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/watch/1.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h4>save 60%</h4>
                                <h2>women</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-3 col-md-6">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'fashion'}">
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/watch/3.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h4>save 60%</h4>
                                <h2>women</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- four banner -->

<!-- absolute banner -->
<section class="banner-furniture absolute_banner ratio3_2">
    <div class="container">
        <div class="row partition3">
            <div class="col-md-4">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                    <div class="collection-banner p-left text-start">
                        <img src="assets/images/banner/6.jpg" alt="" class="img-fluid">
                        <div class="absolute-contain">
                            <h3>casual collection</h3>
                            <h4>festive sale</h4>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                    <div class="collection-banner p-left text-start">
                        <img src="assets/images/banner/7.jpg" alt="" class="img-fluid">
                        <div class="absolute-contain">
                            <h3>going out collection</h3>
                            <h4>upto 80% off</h4>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                    <div class="collection-banner p-left text-start">
                        <img src="assets/images/banner/8.jpg" alt="" class="img-fluid">
                        <div class="absolute-contain">
                            <h3>shoes & sandle</h3>
                            <h4>new collection</h4>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- absolute banner -->

<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card">
                    <h5 class="card-header">Classes</h5>
                    <div class="card-body">
                        <h5>Add class with collection-banner</h5>
                        <h5>contain-align - .text-start, .text-center, .text-end</h5>
                        <h5>contain-position - .p-left, .p-center, .p-right</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>