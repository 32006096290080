import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { User } from "../../../shared/classes/user";
import { FirestoreService } from "../../../services/firestore.service";
import { Platform } from "@angular/cdk/platform";
import { Router } from "@angular/router";
import { HttpService } from "../../../services/http.service";
import { AuthService } from "../../../shared/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { EventsService } from "../../../shared/services/events.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ProductService } from "../../../shared/services/product.service";
import Swal from "sweetalert2";
import { switchAll } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild("ageVerification") AgeVerificationModal: TemplateRef<any>;
    @ViewChild("content2") content2: TemplateRef<any>;

    user = new User();
    email: string = "";
    password: string = "";
    urlDefault: string = '/products';
    public ageVerificationForm: UntypedFormGroup;

    showResetPassword: boolean = false;
    newPassword: string = '';
    newPasswordConfirm: string = '';
    private userPHP: any;
    private loginMethods = {
        1: this.singIn,
        2: this.loginWithGoogle,
        3: this.loginWithFacebook
    };
    data: any = {};
    typePassword: any = {
        password: 'password',
    }

    constructor(
        private service: HttpService,
        private auth: AuthService,
        // private loadingController: LoadingController,
        private router: Router,
        private events: EventsService,
        private firestore: FirestoreService,
        private platform: Platform,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        public productService: ProductService,
        private toastrService: ToastrService
    ) {
        this.ageVerificationForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        })

        this.service.sendGET_SettingsApp('RegisterScreen').subscribe(res => {
            // console.log("RegisterScreen: ", res);
            this.data = res;
        })
    }

    ngOnInit(): void {
        this.auth.getUser2().then(async userLocal => {
            this.user = userLocal;
            
            if (this.user.emailVerified) {
                await this.router.navigate(['/pages/mi-cuenta']);
            } 
        }).catch(e => {
            this.signOut();
        })
    }

    singIn() {
        // console.log(this.email);
        this.auth.loginWithEmail(this.email, this.password).then(res => {
            // console.log("loginWithEmail: ", res)
            if (res.user.emailVerified) {
                console.log("Email verified");
                let message = {
                    "emailVerified": true,
                }
                this.service.updateUser(message, res.user.uid).subscribe(response => {
                    // console.log("update: ", response);
                    this.service.getOneUser(res.user.uid).subscribe(async userLocal => {
                        this.user = userLocal;
                        localStorage.setItem('session_last_email', this.user.email);
                        localStorage.setItem('session_last_password', this.password)
                        this.events.publish('session-start', true);
                        localStorage.setItem('session_type', 'correo');
                        // console.log('userLocal', userLocal)
                        this.user.who_receives = this.user.who_receives == '' ? res.user.displayName : this.user.who_receives;
                        this.toastrService.success("Bienvenido de nuevo " + res.user.displayName + "!", "Bienvenido");
                        this.productService.verifyDataShoppingCart(userLocal.uid);
                        await this.redirect();
                    })
                    // this.auth.getUser2().then( async userLocal => {
                    //
                    // }).catch((e) => {
                    //     console.log('catch getUser2', e);
                    // });
                })
            } else {
                Swal.close();
                this.signOut();
                // this.toastrService.error('Email no verificado', 'Error!');
                Swal.fire(
                    'Correo electrónico no verificado',
                    `Por favor validar su correo electrónico`,
                    'info'
                );
            }
        }).catch(err => {
            if (err.code == "auth/wrong-password") {
                Swal.fire('Error', 'La contraseña incorrecta', 'error');
            } else {
                this.firestore.getWhere('UsersPHP', 'email', this.email).subscribe(res => {
                    // console.log('response get UsersPHP', res);
                    if (res.length > 0) {
                        this.userPHP = res[0];
                        this.openVerticallyCentered(this.content2);
                    } else {
                        Swal.close();
                        this.signOut();
                        this.toastrService.error(err.message);
                        console.log("Error", err)
                    }
                })
            }
        });
    }

    verifyUser() {
        // console.log('userLocal', this.user);
    }

    resetPassword() {
        // this.showResetPassword = true;
    }


    login(type) {
        Swal.fire({
            icon: 'info',
            title: `Iniciando sesión, espere un momento por favor`,
            showConfirmButton: false,
            backdrop: 'static',
            allowOutsideClick: false,
        });
        const loginMethod = this.loginMethods[type];
        if (loginMethod) {
            loginMethod.call(this);
        } else {
            console.error(`Unsupported login type: ${type}`);
            Swal.close();
            Swal.fire('Opps', `se ha producido un error al inciar sesión, inténtelo de nuevo.`, 'error');
        }
    }

    openVerticallyCentered(content) {
        this.modalService.open(content, {
            centered: true,
            animation: true,
            backdrop: 'static',
            keyboard: false,
        });
    }

    sendResetPassword() {
        let email = this.ageVerificationForm.value.email;
        console.log('email', email)
        this.auth.resetPassword(email).then(r => {
            // console.log("resetPassword: ", r);
            // this.toastrService.info('Hemos enviado un enlace para cambiar su contraseña', 'Compruebe su correo electrónico');
            Swal.fire(
                'Hemos enviado un enlace para cambiar su contraseña',
                `Compruebe su correo electrónico`,
                'info'
            );
        }).catch((error) => {
            console.log(error)
            this.toastrService.error('Opps ' + error, 'Error');
        });
        this.modalService.dismissAll();
    }

    loginWithGoogle() {
        this.auth.loginWithGoogle().then(res => {
            // console.log("loginWithGoogle: ", res)
            if (res.additionalUserInfo.isNewUser) {
                this.toastrService.info('Signing in with google, please wait')
                this.user.uid = res.user.uid;
                this.user.email = res.user.email;
                this.user.emailVerified = true;
                this.user.photo = res.user.photoURL;
                this.user.firstName = res.user.displayName;
                this.user.username = '@' + res.user.email.substring(0, 4) + Math.floor(Math.random() * 1001);
                this.user.phone = res.user.phoneNumber;
                this.firestore.save('Users', { ...this.user }).then(response => {
                    this.auth.getUser2().then(async userLocal => {
                        // console.log('save userLocal ', response);
                        localStorage.setItem('session_last_email', this.user.email);
                        localStorage.setItem('session_type', 'google');
                        this.events.publish('session-start', true);
                        this.toastrService.success("Bienvenido " + this.user.firstName + ", hemos creado su cuenta", "Success");
                        this.productService.verifyDataShoppingCart(userLocal.uid);
                        await this.redirect();
                    })
                }).catch(error => {
                    this.toastrService.error("An error has occurred when saving the user", "Error");
                    console.log('error save new user', error);
                })
            } else {
                this.auth.getUser2().then(async userLocal => {
                    // console.log("userlocal: else ", userLocal)
                    this.toastrService.success("Bienvenido de nuevo " + userLocal.firstName + "!", "Success");
                    this.events.publish('session-start', true);
                    localStorage.setItem('session_last_email', userLocal.email);
                    localStorage.setItem('session_type', 'google');
                    console.log("is not new user");
                    this.productService.verifyDataShoppingCart(userLocal.uid);
                    await this.redirect();
                })
            }
        }).catch(error => {
            Swal.close();
            console.log('error login with google', error);
            this.toastrService.error("An error has occurred when logging in with google", "Error");
            this.signOut();
        });
    }

    async redirect() {
        let urlHistory = localStorage.getItem('history');
        console.log('llego al redirect', urlHistory == '' || urlHistory == undefined ? this.urlDefault : urlHistory)
        window.location.href = urlHistory == '' || urlHistory == undefined ? this.urlDefault : urlHistory;
        // await this.router.navigate([urlRedirect]);
    }

    loginWithFacebook() {
        this.auth.loginWithFacebook().then(res => {
            if (res.additionalUserInfo.isNewUser) {
                this.toastrService.info('Signing in with facebook, please wait');
                this.user.uid = res.user.uid;
                this.user.email = res.user.email;
                this.user.photo = res.user.photoURL;
                this.user.firstName = res.user.displayName;
                this.user.lastName = res.user.displayName;
                this.user.phone = res.user.phoneNumber;
                this.user.emailVerified = true;
                this.user.username = '@' + res.user.email.substring(0, 4) + Math.floor(Math.random() * 1001);
                this.firestore.save('Users', { ...this.user }).then(response => {
                    this.auth.getUser2().then(async userLocal => {
                        // console.log('save user from facebook', response);
                        localStorage.setItem('session_last_email', this.user.email);
                        localStorage.setItem('session_type', 'facebook');
                        this.events.publish('session-start', true);
                        this.toastrService.success("Bienvenido " + this.user.firstName + ", we have created your account", "Success");
                        this.productService.verifyDataShoppingCart(userLocal.uid);
                        await this.redirect();
                    })
                }).catch(error => {
                    this.toastrService.error("An error has occurred when saving the user", "Error");
                    console.log('error save new user', error);
                })
            } else {
                this.auth.getUser2().then(async userLocal => {
                    console.log("userlocal: else ", userLocal)
                    this.toastrService.success("Bienvenido de nuevo " + userLocal.firstName + "!", "Success");
                    this.events.publish('session-start', true);
                    localStorage.setItem('session_last_email', userLocal.email);
                    localStorage.setItem('session_type', 'facebook');
                    console.log("is not new user");
                    this.productService.verifyDataShoppingCart(userLocal.uid);
                    await this.redirect();
                })
            }
        }).catch(error => {
            Swal.close();
            console.log('error login with facebook', error);
            this.toastrService.error("An error has occurred when logging in with facebook", "Error");
            this.signOut();
        });
    }

    updatePassword() {
        if (this.newPassword === this.newPasswordConfirm) {
            this.modalService.dismissAll('update password');
            Swal.fire({
                title: 'Procesando',
                text: 'Actualizando contraseña, espere un momento por favor',
                icon: "info",
                backdrop: true,
                heightAuto: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false
            })
            this.auth.registerWithEmail(this.email, this.newPassword).then(res => {
                // console.log('response registerWithEmail', res);
                if (res.additionalUserInfo.isNewUser) {
                    this.user.uid = res.user.uid;
                    this.user.email = this.email;
                    this.user.photo = '';
                    this.user.firstName = this.userPHP.firstName != undefined ? this.userPHP.firstName : '';
                    this.user.lastName = this.userPHP.lastName != undefined ? this.userPHP.lastName : '';
                    /*let direction = {
                        city: this.userPHP.city,
                        country: this.userPHP.country,
                        state: this.userPHP.state,
                        zipCode: this.userPHP.postalcode,
                    }
                    this.user.direction.push(<Direction_User>direction);*/
                    this.firestore.save('Users', { ...this.user }).then(response => {
                        // console.log('response save new user', response);
                        Swal.fire({
                            title: 'Exito',
                            text: 'Gracias por actualizar su contraseña, Le hemos enviado un correo electrónico de verificación, eso le permitira poder realizar compras dentro de nuetra tienda web',
                            icon: "success",
                            backdrop: true,
                            heightAuto: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then(() => {
                            this.redirect();
                        });
                    })
                } else {
                    Swal.close();
                    Swal.fire('Opps', 'Se ha producido un error al actualizar su contraseña, vuelva a intentarlo más tarde.', 'error');
                }
            }).catch(err => {
                console.log('err with update', err);
                Swal.close();
                Swal.fire('Opps', 'Se ha producido un error al actualizar su contraseña, vuelva a intentarlo más tarde.', 'error');
            })
        } else {
            Swal.fire('Error', 'Contraseñas no coinciden, favor de verificar', "error")
        }
    }

    viewPassword() {
        this.typePassword.password = (this.typePassword.password === 'password') ? 'text' : 'password';
    }

    signOut() {
        this.auth.logout().then((e) => {
        }).catch((e) => {
            console.log('catch signOut', e);
        });
    }

}
