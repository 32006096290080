import {Component, EventEmitter, Inject, Input, Output, PLATFORM_ID} from '@angular/core';
import {Options} from "ng5-slider";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-diametro-interior',
  templateUrl: './diametro-interior.component.html',
  styleUrls: ['./diametro-interior.component.scss']
})
export class DiametroInteriorComponent {

  // Using Output EventEmitter
  @Output() diametroInterior : EventEmitter<any> = new EventEmitter<any>();

  // define min, max and range
  @Input() min: number;
  @Input() max: number;

  public collapse: boolean = true;
  public isBrowser: boolean = false;

  public DI: any;

  options: Options = {
    floor: 0,
    ceil: 1000
  };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true; // for ssr
    }
  }

  ngOnInit(): void {
    console.log()
    this.max = 1e3;

  }

  // Range Changed
  appliedFilter(event: any) {
    this.DI = { minDI: event.value, maxDI: event.highValue };
    this.diametroInterior.emit(this.DI);
  }
  
}
