import {Component, EventEmitter, Inject, Input, Output, PLATFORM_ID} from '@angular/core';
import {Options} from "ng5-slider";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: 'app-largo',
    templateUrl: './largo.component.html',
    styleUrls: ['./largo.component.scss']
})
export class LargoComponent {
    
    // Using Output EventEmitter
    @Output() largo: EventEmitter<any> = new EventEmitter<any>();

    // define min, max and range
    @Input() min: number;
    @Input() max: number;

    public collapse: boolean = true;
    public isBrowser: boolean = false;

    public L: any;

    options: Options = {
        floor: 0,
        ceil: 1000
    };

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            this.isBrowser = true; // for ssr
        }
    }

    ngOnInit(): void { this.max = 1e3; }

    // Range Changed
    appliedFilter(event: any) {
        this.L = {minL: event.value, maxL: event.highValue};
        this.largo.emit(this.L);
    }
}
