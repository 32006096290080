import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {Location} from '@angular/common';
import {HttpService} from './http.service';
import {Router} from '@angular/router';
import {AuthService} from '../shared/services/auth.service';


const state = {
    settingsGeneral: []
};

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private collectionName = '/assets/images/categories/icons/';

    constructor(
        private _location: Location,
        private service: HttpService,
        private router: Router,
        private auth: AuthService,
    ) {

    }

    alertModal(txt = '', type = 2) {
        switch (type) {
            case 1:
                Swal.fire({
                    icon: 'info',
                    title: `Procesando ${txt}, espere un momento por favor`,
                    showConfirmButton: false,
                    backdrop: 'static',
                    allowOutsideClick: false,
                });
                break;
            case 2:
                Swal.fire({
                    icon: 'success',
                    title: 'Excelente!',
                    html: `${txt} procesado correctamente!`,
                    showConfirmButton: false,
                    timer: 1.5e3,
                });
                break;
            case 3:
                Swal.fire('Opps', `se ha producido un error al procesar ${txt}, inténtelo de nuevo.`, 'error');
                break;
        }
    }

    alertOutStock(stock) {
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'You cannot add more items than are available: ' + stock,
            showConfirmButton: false,
            timer: 2.5e3,
        });
    }

    goBack() {
        this._location.back();
    }

    alertPermissionDenied(page: string) {
        this.router.navigate(['/']).then(() =>
            Swal.fire({
                icon: 'error',
                title: 'permission denied',
                text: 'Dear user, you do not have the necessary permissions to access this module: ' + page,
            })
        );
    }

    convertSecondsToDate(seconds: number): string {
        const date = new Date(seconds * 1e3); // create Date object from seconds
        const day = date.getDate().toString().padStart(2, '0'); // extract day and format with leading zero
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // extract month (note: months are zero-indexed) and format with leading zero
        const year = date.getFullYear().toString(); // extract year
        return `${year}-${month}-${day}`; // return formatted date string
    }

    formattedNumber(number: string): string {
        return number.slice(0, 4) + ' ' + number.slice(4);
    }

    formattedDate() {
        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const WEEKS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let now = new Date();
        return WEEKS[now.getDay()] + ', ' + now.getDate() + ' of ' + MONTHS[now.getMonth()] + ' of ' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes();
    }


    alertLoading() {
        Swal.fire({
            html: '<br>  <div class="spinner-border text-dark" role="status">' +
                '    <span class="visually-hidden"></span>' +
                '  </div> <br> <br> loading... ',
            showConfirmButton: false,
            backdrop: 'static',
            allowOutsideClick: false,
        });
    }

    uidUser() {
        this.auth.getUser2().then(async userLocal => {
            return userLocal.uid;
        }, (e) => {
        });
    }

}
