<section>
    <div class="container py-5">
        <div class="main-timeline-2">
            <ng-container *ngFor='let entry of item;let i = index'>
                <div class="timeline-2 {{ i%2!==0 ? 'left-2' : 'right-2' }}">
                    <div class="card">
                        <img [hidden]="true" src="" class="card-img-top" alt="Responsive image">
                        <div class="card-body p-4">
                            <h4 class="fw-bold mb-4"> {{ entry.title }} </h4>
                            <p [hidden]="true" class="text-muted mb-4"><i class="far fa-clock" aria-hidden="true"></i></p>
                            <p class="mb-0" [textContent]="entry.detail"></p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
