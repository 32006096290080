<header class="sticky" style="background-color: {{ color }}" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="px-5">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left">
                        <app-left-menu></app-left-menu>
                        <div class="brand-logo">
                            <a routerLink="/" id="headerlogo">
                                <img [src]="themeLogo" class="img-fluid" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <div *ngIf="!boxSearch">
                            <app-menu></app-menu>
                        </div>
                        <div *ngIf="boxSearch" class="d-grid align-items-center">
                            <form class="form_search" role="form">
                                <input (keyup.enter)="search()" id="query search-autocomplete" name="query" [(ngModel)]="contentSearch" type="search" placeholder="Búsqueda de productos" class="nav-search nav-search-field" aria-expanded="true">
                                <button (click)="search()" type="button" name="nav-submit-button" class="btn-search">
                                    <i class="ti-search"></i>
                                </button>
                            </form>
                        </div>
                        <div>
                            <app-settings (showSearch)="showSearch($event)" ></app-settings>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
