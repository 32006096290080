import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-descarga',
  templateUrl: './descarga.component.html',
  styleUrls: ['./descarga.component.scss']
})
export class DescargaComponent {

  logo: string = '';
  downloadData: any = {};
  languageCode: string = "es";
  @ViewChild('main') main: ElementRef;
  anchoPagina: number = 0;

  constructor(
    private service: HttpService,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
  ) {
    var path = window.location.pathname;
    var format_path = path.split('/');
    //  console.log(format_path[1]);
    if (format_path[1] === 'descarga') {
      this.languageCode = "es";
    } else {
      this.languageCode = "en";
    }
  }

  ngOnInit(): void {
    // this.generateSitemapXml();
    this.getSettingGeneral();
  }

  @HostListener('mouseenter') onMouseEnter() {
    // console.log(this.el.nativeElement);

    // console.log('Alto-->' + this.el.nativeElement.offsetHeight);
    // console.log('Ancho', this.el.nativeElement.offsetWidth);
    this.anchoPagina = this.el.nativeElement.offsetWidth;
    
    if (this.el.nativeElement.offsetWidth <= 768) {
      // Cambiar el estilo de fondo
      if (this.downloadData?.banner_background?.mobile?.active) {
        // console.log("this.downloadData.banner_background.mobile.url: ", this.downloadData.banner_background.mobile.url);

        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background', 'transparent url(' + this.downloadData.banner_background.mobile.url + ')');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-size', 'cover');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-position', 'center');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-position-y', 'bottom');
      }
    } else {
      // Cambiar el estilo de fondo
      if (this.downloadData?.banner_background?.web?.active) {
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background', 'transparent url(' + this.downloadData.banner_background.web.url + ')');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-size', 'cover');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-repeat', 'no-repeat');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-position', 'center');
        this.renderer.setStyle(this.el.nativeElement.querySelector('.body'), 'background-position-y', 'center');
      }
    }
  }

  getSettingGeneral() {
    this.service.sendGET_SettingsApp('Store').subscribe((res) => {
      if (res?.download) {
        this.downloadData = res?.download;
        this.downloadData.textData = res?.download.translate[this.languageCode];
        // console.log("downloadData: ", this.downloadData);
        setTimeout(() => {
          this.onMouseEnter();
        }, 100);
      } else {
        this.router.navigate(['/home']);
      }
    });
  }

  generateSitemapXml(): HttpResponse<string> {
    const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
        <loc>https://midominio.com/page1</loc>
      </url>
      <url>
        <loc>https://midominio.com/page2</loc>
      </url>
      <!-- Agrega más URLs según sea necesario -->
    </urlset>`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/xml',
    });

    return new HttpResponse({
      body: xmlContent,
      headers: headers,
    });
  }
}
