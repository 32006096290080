<owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
  <ng-container *ngFor="let cats of categories">
    <ng-template carouselSlide>
      <div>
        <div class="category-wrapper" [ngClass]="class">
          <div>
            <img [src]="cats.image" class="img-fluid w-auto img-categories" alt="">
            <h4 [title]="cats.title" >{{ countCharacter(cats.title)}}</h4>
            <ul class="category-link" [innerHTML]="cats.text"></ul>
            <a [routerLink]="['/products',cats.url_category]" class="btn btn-outline">
               Ver mas
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
