import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent {

  public phoneWhatsapp: number = Number(localStorage.getItem('phoneWhatsapp')) || 0;

  constructor() {
  }

}
