import {Component} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-loading-gif',
    templateUrl: './loading-gif.component.html',
    styleUrls: ['./loading-gif.component.scss']
})
export class LoadingGifComponent {
    public loadGif: string = environment.loadGif;
}
