import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class WompiService {

    wompi = environment.wompi;
    UrlBase = environment.urlBaseWompy;
    apikey = environment.apikey;
    getDatos;

    constructor(
        private http: HttpClient,
    ) {
    }

    wompiApiAuthentication() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                "api-key": this.apikey
            });

            this.http.post(this.UrlBase + "/token", this.wompi, {headers: headers})
                .subscribe(data => {
                    console.log("wompiApiAuthentication: ", data);
                    resolve(data);
                }, error => {
                    console.log("Error #:", error);
                    reject(error);
                });
        })
    }

    EnlacePago(data) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({
                "api-key": this.apikey
            });
            console.log("http: ", data);
            // var raw = JSON.stringify(data);

            this.http.post(this.UrlBase + "/link/payment", data, {headers: headers})
                .subscribe(data => {
                    console.log("EnlacePago: ", data);
                    resolve(data);
                }, error => {
                    console.log("Error EnlacePago #:", error);
                    reject(error);
                });
        })
    }

    EnlacePagoId(data) {
        // Devolvemos una promesa para manejar resultados asincrónicos
        return new Promise((resolve, reject) => {
            // Configuramos los encabezados de la solicitud HTTP con la clave de la API
            const headers = new HttpHeaders({
                "api-key": this.apikey
            });

            // Realizamos una solicitud HTTP POST al servidor
            this.http.post(this.UrlBase + "/link/payment/" + data.id, { token: data.token }, { headers: headers })
                .subscribe((res: any) => {
                    console.log("EnlacePagoId: ", res);
                    if (res.body) {
                        try {
                            // Intentamos analizar la respuesta JSON si tiene un cuerpo
                            let data_wompy = JSON.parse(res.body);
                            resolve(data_wompy); // Resolvemos la promesa con los datos analizados
                        } catch (parseError) {
                            console.log("Error parsing JSON:", parseError);
                            reject(parseError); // Rechazamos la promesa si hay un error al analizar JSON
                        }
                    } else {
                        console.log("Empty response body");
                        reject("Empty response body"); // Rechazamos la promesa si la respuesta no tiene cuerpo
                    }
                }, error => {
                    console.log("Error EnlacePagoId #:", error);
                    reject(error); // Rechazamos la promesa si ocurre un error en la solicitud
                });
        });
    }

}
