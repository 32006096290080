<!--modal popup start-->
<ng-template class="theme-modal" #ageVerification let-modal>
    <div class="modal-content">
        <div class="modal-body modal13">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="age-content">
                                <h2>Restablecer contraseña</h2>
                                <h4 style="text-transform: none !important;" >Introduzca la dirección de correo electrónico con la que se ha registrado anteriormente:</h4>
                                <form id="demoForm">
                                    <div>
                                        <input type="text" name="email" id="email" [formControl]="ageVerificationForm.controls['email']" value="" class="form-control" placeholder="Introduzca su correo electrónico">
                                    </div>
                                    <button [disabled]="!ageVerificationForm.valid" type="submit" (click)="sendResetPassword()" class="btn btn-solid mx-1">
                                        enviar
                                    </button>
                                    <button type="submit" (click)="closeModal()" class="btn btn-solid-danger mx-1">
                                        cancelar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->
