import {Component, Inject, Injectable, Input, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {isPlatformBrowser} from "@angular/common";
import {AuthService} from "../../../services/auth.service";
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
    @ViewChild("ageVerification") AgeVerificationModal: TemplateRef<any>;

    public closeResult: string;
    public ageVerificationForm: UntypedFormGroup;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        private auth: AuthService,
        private toastrService: ToastrService,
    ) {
        this.ageVerificationForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        })
    }

    ngOnInit(): void {
        this.ageVerificationForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        })
    }

    ngAfterViewInit(): void {
        // if(localStorage.getItem("ageVerification") !== 'true')
        // this.openModal();
    }

    openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.AgeVerificationModal, {
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                centered: true,
                windowClass: 'bd-example-modal-md theme-modal agem'
            }).result.then((result) => {
                `Result ${result}`
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    sendResetPassword() {
        let email = this.ageVerificationForm.value.email;
        console.log('email', email)
        this.auth.resetPassword(email).then(r => {
            console.log("resetPassword: ", r);
            this.toastrService.info('Hemos enviado un enlace para cambiar su contraseña', 'Compruebe su correo electrónico');
        }).catch((error) => {
            console.log(error)
            this.toastrService.error('Opps ' + error, 'Error');
        });
        this.closeModal();
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {

    }

}
