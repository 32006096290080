import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // return  args.product_promote ? args.discount ? args.price - (args.price * args.discount / 100) : args.price : args.price;
    return  args.product_promote ? args.price_tax_product_discount : args.price;
  }

}
