<app-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="container">
      <div class="checkout-page">
        <div class="checkout-form">
          <form>
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-xs-12">
                <div class="checkout-title">
                  <h3>Billing Details</h3>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">First Name</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">Last Name </div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">Phone</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">Email Address</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="field-label">Country</div>
                    <select>
                      <option>India</option>
                      <option>South Africa</option>
                      <option>United State</option>
                      <option>Australia</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="field-label">Address</div>
                    <input type="text" name="field-name" value="" placeholder="Street address">
                  </div>
                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="field-label">Town/City</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-12 col-sm-6 col-xs-12">
                    <div class="field-label">State / County</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-12 col-sm-6 col-xs-12">
                    <div class="field-label">Postal Code</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="stripe-section">
                      <h5>stripe js</h5>
                      <div class="content">
                        <h5>dummy test</h5>
                        <table>
                          <tr>
                            <td>cart number</td>
                            <td>4242424242424242</td>
                          </tr>
                          <tr>
                            <td>mm/yy</td>
                            <td>2/2020</td>
                          </tr>
                          <tr>
                            <td>cvc</td>
                            <td>2222</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row margin-cls">
                  <div class="col-12">
                    <div class="stripe-section">
                      <h5>paypal</h5>
                      <div class="content">
                        <h5>dummy test</h5>
                        <h5>I set total payment to $0.01 for static demo</h5>
                        <table>
                          <tr>
                            <td>cart number</td>
                            <td>4152521541244</td>
                          </tr>
                          <tr>
                            <td>mm/yy</td>
                            <td>11/18</td>
                          </tr>
                          <tr>
                            <td>cvc</td>
                            <td>521</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-xs-12">
                <div class="checkout-details">
                  <div class="order-box">
                    <div class="title-box">
                      <div>Product <span> Total</span></div>
                    </div>
                    <ul class="qty">
                      <li>Pink Slim Shirt × 1 <span>$25.10</span></li>
                      <li>SLim Fit Jeans × 1 <span>$555.00</span></li>
                    </ul>
                    <ul class="sub-total">
                      <li>Subtotal <span class="count">$380.10</span></li>
                      <li>Shipping <div class="shipping">
                        <div class="shopping-option">
                          <input type="checkbox" name="free-shipping" id="free-shipping">
                          <label for="free-shipping">Free Shipping</label>
                        </div>
                        <div class="shopping-option">
                          <input type="checkbox" name="local-pickup" id="local-pickup">
                          <label for="local-pickup">Local Pickup</label>
                        </div>
                      </div>
                      </li>
                    </ul>
  
                    <ul class="total">
                      <li>Total <span class="count">$620.00</span></li>
                    </ul>
                  </div>
  
                  <div class="payment-box">
                    <div class="upper-box">
                      <div class="payment-options">
                        <ul>
                          <li>
                            <div class="radio-option">
                              <input type="radio" name="payment-group" id="payment-1" checked>
                              <label for="payment-1">Check Payments<span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span></label>
                            </div>
                          </li>
  
                          <li>
                            <div class="radio-option">
                              <input type="radio" name="payment-group" id="payment-2">
                              <label for="payment-2">Cash On Delivery<span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span></label>
                            </div>
                          </li>
                          <li>
                            <div class="radio-option paypal">
                              <input type="radio" name="payment-group" id="payment-3">
                              <label for="payment-3">PayPal<span class="image"><img src="assets/images/paypal.png" alt="" /></span></label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-end">
                      <a [routerLink]="['shop/checkout']" class="btn-solid btn">Place Order</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</section>
<!-- section End -->