<!-- category 2 -->
<section class="pt-0">
    <div class="container">
        <div class="row category-border">
            <div *ngFor="let category of categories" class="col-sm-4 border-padding py-2">
                <div class="category-banner">
                    <div class="d-grid justify-content-center">
<!--                        <img [defaultImage]="loadGif" [lazyLoad]="'assets/images/categories/14.png'" class="img-fluid" alt="">-->
<!--                        <img [defaultImage]="loadGif" [lazyLoad]="category.image" class="img-fluid" alt="">-->
                        <img [src]="category.category_image_sm" class="img-fluid w-auto img-categories" alt="">
                    </div>
                    <div class="category-box text-center px-4">
                        <a [title]="category.category_name" [routerLink]="[category.url_category]">
                            <h2 style="font-size: 1rem" [textContent]="global.countCharacter(category.category_name)"></h2>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

