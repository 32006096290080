<app-breadcrumb [title]="'Compare'" [breadcrumb]="'Compare'"></app-breadcrumb>
<!-- section start -->
<section class="compare-section section-b-space ratio_asos">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="slide-4">
                    <owl-carousel-o [options]="CompareSliderConfig">
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div>
                                <div class="compare-part">
                                    <button type="button" class="close-btn">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div class="img-secton">
                                        <div>
                                            <img src="assets/images/product/fashion/1.jpg" class="img-fluid blur-up lazyload bg-img"
                                                alt="">
                                        </div>
                                        <a href="#">
                                            <h5>slim fit shirt</h5>
                                        </a>
                                        <h5>$555</h5>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>discription</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>Brand Name</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Zara</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>size</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>XL, L, M, S, XS</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>color</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>Red , Blue , Pink</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>material</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>cotton</p>
                                        </div>
                                    </div>
                                    <div class="detail-part">
                                        <div class="title-detail">
                                            <h5>availability</h5>
                                        </div>
                                        <div class="inner-detail">
                                            <p>In stock</p>
                                        </div>
                                    </div>
                                    <div class="btn-part">
                                        <a href="#" class="btn btn-solid">add to cart</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->