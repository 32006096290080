<div #main id=main *ngIf="downloadData && downloadData.logo"
    class="body w-100 h-100 d-flex justify-content-center align-items-{{ (!downloadData?.textData?.image_left?.active) ? 'top' : 'center' }} p-5">
    <div *ngIf="anchoPagina > 768" class="view-web d-flex align-items-center justify-content-center">
        <div class="container text-center p-0 m-0">
            <div class="row">
                <div class="col-6">
                    <div *ngIf="downloadData?.textData?.image_left?.active" class="center column2">
                        <div>
                            <img [src]="downloadData?.textData?.image_left?.url"
                                class="img-fluid lazy-loading" alt="image-balls-more">
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-5">
                    <div class="left column2">
                        <div>
                            <img *ngIf="downloadData?.logo?.active" [src]="downloadData?.logo?.url"
                                class="w-75 img-fluid lazy-loading" alt="image-balls-more">
                            <h1 *ngIf="downloadData?.textData?.title?.active"
                                [ngStyle]="{'color': downloadData?.textData?.title?.color}"
                                id="text-banner">
                                {{downloadData?.textData?.title?.text}}
                            </h1>
                            <h3 *ngIf="downloadData?.textData?.subtitle?.active"
                                [ngStyle]="{'color': downloadData?.textData?.subtitle?.color}"
                                id="sub-text" class="w-75 mx-auto">
                                {{downloadData?.textData?.subtitle?.text}}
                            </h3>
                            <h3 *ngIf="downloadData?.textData?.subtext?.active"
                                [ngStyle]="{'color': downloadData?.textData?.subtext?.color}"
                                id="sub-text-banner">
                                {{downloadData?.textData?.subtext?.text}}
                            </h3>
                            <div class="container text-center">
                                <div class="row">
                                    <div class="col-6">
                                        <a href="https://play.google.com/store/apps/details?id=com.Ianosappsvballsmore"
                                            target="_blank" id="link-android"
                                            style="text-decoration: none;">
                                            <img src="assets/img/google play/google play@2x.png"
                                                class="column2" alt="image-balls-more">
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="https://apps.apple.com/us/app/balls-more/id6554464317?platform=iphone"
                                            target="_blank" id="link-android"
                                            style="text-decoration: none;">
                                            <img src="assets/img/app store/app store@2x.png" class="column2"
                                                alt="image-balls-more">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="anchoPagina <= 768"class="view-movil mt-2">
        <div class="container text-center p-0">
            <div class="row">
                <div class="col">
                    <div class="center column2">
                        <div>
                            <img *ngIf="downloadData?.logo?.active" [src]="downloadData?.logo?.url"
                                class="w-75 img-fluid lazy-loading" alt="image-balls-more">
                            <h1 *ngIf="downloadData?.textData?.title?.active"
                                [ngStyle]="{'color': downloadData?.textData?.title?.color}"
                                id="text-banner">
                                {{downloadData?.textData?.title?.text}}
                            </h1>
                            <h3 *ngIf="downloadData?.textData?.subtitle?.active"
                                [ngStyle]="{'color': downloadData?.textData?.subtitle?.color}"
                                class="w-75 mx-auto"
                                id="sub-text">
                                {{downloadData?.textData?.subtitle?.text}}
                            </h3>
                            <h3 *ngIf="downloadData?.textData?.subtext?.active"
                                [ngStyle]="{'color': downloadData?.textData?.subtext?.color}"
                                id="sub-text-banner">
                                {{downloadData?.textData?.subtext?.text}}
                            </h3>
                            <div class="container text-center">
                                <div class="row">
                                    <div class="col-12 px-5">
                                        <a href="https://play.google.com/store/apps/details?id=com.Ianosappsvballsmore"
                                            target="_blank" id="link-android"
                                            style="text-decoration: none;">
                                            <img src="assets/img/google play/google play@2x.png"
                                                class="column2" alt="image-balls-more">
                                        </a>
                                    </div>
                                    <div class="col-12 px-5">
                                        <a href="https://apps.apple.com/us/app/balls-more/id6554464317?platform=iphone"
                                            target="_blank" id="link-android"
                                            style="text-decoration: none;">
                                            <img src="assets/img/app store/app store@2x.png" class="column2"
                                                alt="image-balls-more">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="center column2">
                        <div *ngIf="downloadData?.textData?.image_left?.active">
                            <img [src]="downloadData?.textData?.image_left?.url"
                                class="img-fluid lazy-loading" alt="image-balls-more">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>