import {Component, OnInit, Input} from '@angular/core';
import {Product} from '../../../../shared/classes/product';
import {ProductService} from '../../../../shared/services/product.service';
import {HttpService} from "../../../../services/http.service";
import {element} from "protractor";

@Component({
    selector: 'app-related-product',
    templateUrl: './related-product.component.html',
    styleUrls: ['./related-product.component.scss']
})
export class RelatedProductComponent implements OnInit {

    @Input() type: string
    @Input() urlClasificacion: string
    @Input() urlSubcategory: string

    public products: Product[] = [];
    public dataApi = {
        'limit': 10,
        'offset': 1,
        'type': 0,
        'url': '',
    }

    constructor(
        public productService: ProductService,
        private service: HttpService,
    ) {
        this.dataApi.type = 3;
        this.dataApi.url = this.urlClasificacion;
        (this.dataApi.url != undefined) ? this.getProductsClassification() : this.getProductsSubcategories();
    }

    ngOnInit(): void {
    }

    getProductsClassification(){
        this.service.getProductsByUrl(this.dataApi).subscribe(response => {
            console.log('response by clasificacion', response);
            if (!response.success) this.getProductsSubcategories();
            else this.products = this.productService.listProducts(response);
        })
    }

    getProductsSubcategories() {
        this.dataApi.type = 2;
        this.dataApi.url = this.urlSubcategory;
        this.service.getProductsByUrl(this.dataApi).subscribe(response2 => {
            console.log('response by subcategorie', response2);
            if (response2.success) this.products = this.productService.listProducts(response2);
        })
    }

}
