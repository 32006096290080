import {Component, OnInit, Input} from '@angular/core';
import {element} from "protractor";
import {breadcrumbDynamic} from "../../classes/product";
import {Title} from '@angular/platform-browser';


@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    @Input() title: string;
    @Input() breadcrumb: string;
    @Input() breadcrumbDynamic: breadcrumbDynamic[] = [];
    pathAbsolute = [
        {'path': '/'},
        {'path': ''},
        {'path': ''},
        {'path': ''},
        {'path': ''},
    ];

    count: number = 1;

    constructor(
        private titleService: Title
    ) {
    }

    async ngOnInit(): Promise<void> {
        // console.table(this.breadcrumbDynamic);
        let i = 0;
        await this.breadcrumbDynamic.forEach(element => {
            let x = i;
            x--;
            switch (i) {
                case 0:
                    this.pathAbsolute[i].path = '/' + this.verifyUrl(element.url);
                    break;
                case 1:
                    this.pathAbsolute[i].path = this.pathAbsolute[x].path + '/' + element.url;
                    break;
                case 2:
                    this.pathAbsolute[i].path = this.pathAbsolute[x].path + '/' + element.url;
                    break;
                case 3:
                    this.pathAbsolute[i].path = this.pathAbsolute[x].path + '/' + element.url;
                    break;
                case 4:
                    this.pathAbsolute[i].path = this.pathAbsolute[x].path + '/' + element.url;
                    break;
            }
            i++;
        })
        let currentTitle = this.titleService.getTitle();
        console.log('currentTitle',currentTitle);
        let count = i > 0 ? (i - 1) : 0;
        let title = i > 0 ? this.pathAbsolute[count].path : this.title;
        this.titleService.setTitle(currentTitle + ' ' + title.replaceAll('/', ' | '));
    }

    verifyUrl(title){
        return title == 'shop' ? 'products' : title;
    }


}
