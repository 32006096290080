import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { User } from "../../../shared/classes/user";
import { AuthService } from "../../../shared/services/auth.service";
import { FirestoreService } from "../../../services/firestore.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    public checkoutForm: UntypedFormGroup;
    email: string = '';
    password: string = '';
    user = new User();
    typePassword: any = {
        password1: 'password',
        password2: 'password',
    }

    constructor(
        private fb: UntypedFormBuilder,
        private auth: AuthService,
        private toastrService: ToastrService,
        private firestore: FirestoreService,
        private router: Router,
    ) {
        this.checkoutForm = this.fb.group({
            password2: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')]],
            email: ['', [Validators.required, Validators.email]],
        })
    }

    ngOnInit(): void {
    }

    createAccount() {
        this.email = this.checkoutForm.value.email;
        this.password = this.checkoutForm.value.password;
        // console.log('email and password' + this.email + '  ' + this.password);
        this.toastrService.info('Creando usuario, espere un momento por favor');
        this.auth.registerWithEmail(this.email, this.password).then(res => {
            // console.log('response registerWithEmail', res);
            if (res.additionalUserInfo.isNewUser) {
                this.user.uid = res.user.uid;
                this.user.email = this.email;
                this.user.photo = '';
                this.user.firstName = '';
                this.user.lastName = '';
                this.firestore.save('Users', { ...this.user }).then(response => {
                    // console.log('response save new user', response);
                    // this.toastrService.success("Le hemos enviado un correo electrónico de verificación", "Confirme su dirección de correo electrónico");
                    this.sendEmailVerification();
                    Swal.close();
                    Swal.fire(
                        'Le hemos enviado un correo electrónico de verificación',
                        `Confirme su dirección de correo electrónico`,
                        'success'
                    );
                    this.user = null;
                    this.router.navigate(['/pages/login']);
                })
            } else {
                Swal.close();
                this.toastrService.error("Se ha producido un error al registrar el usuario, inténtelo de nuevo más tarde.", "Error");
            }
        }).catch(err => {
            console.log('err with register', err);
            Swal.close();
            this.toastrService.error("Se ha producido un error al registrar el usuario, inténtelo de nuevo más tarde.", "Error");
        })
    }

    viewPassword(type) {
        switch (type) {
            case 1:
                this.typePassword.password1 = (this.typePassword.password1 === 'password') ? 'text' : 'password';
                break;
            case 2:
                this.typePassword.password2 = (this.typePassword.password2 === 'password') ? 'text' : 'password';
                break;
            default:
                break;
        }
    }

    sendEmailVerification() {
        this.auth.sendEmailVerification().then((res) => {
            return true;
        }).catch((error) => {
            return false;
        });
    }
}
