<app-breadcrumb [title]="'Compare'" [breadcrumb]="'Compare'"></app-breadcrumb>
<!-- section start -->
<section class="compare-padding">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="compare-page">
                    <div class="table-wrapper table-responsive">
                        <table class="table">
                            <thead>
                                <tr class="th-compare">
                                    <td>Action</td>
                                    <th class="item-row"><button type="button" class="remove-compare" > Remove </button></th>
                                    <th class="item-row"><button type="button" class="remove-compare" > Remove </button></th>
                                    <th class="item-row"><button type="button" class="remove-compare" > Remove </button></th>
                                    <th class="item-row"><button type="button" class="remove-compare" > Remove </button></th>
                                </tr>
                            </thead>
                            <tbody id="table-compare">
                                <tr>
                                    <th class="product-name">Product Name</th>
                                    <td class="grid-link__title"> Cut Dress  </td>
                                    <td class="grid-link__title"> Floral Dress  </td>
                                    <td class="grid-link__title"> Notched  Dresss  </td>
                                    <td class="grid-link__title"> Notched  Dresss  </td>
                                </tr>
                                <tr>
                                    <th class="product-name ">Product Image</th>
                                    <td class="item-row">
                                        <img src="assets/images/product/fashion/1.jpg" alt="" class="featured-image">
                                        <div class="product-price product_price"> <strong>On Sale: </strong><span>$89,00</span></div>
                                        <form class="variants clearfix">
                                            <input type="hidden">
                                            <button title="Add to Cart" class="add-to-cart btn btn-solid">Add to Cart</button>
                                        </form>
                                        
                                    </td>
                                    <td class="item-row">
                                        <img src="assets/images/product/fashion/2.jpg" alt="" class="featured-image">
                                        <div class="product-price product_price"> <strong>On Sale: </strong><span>$89,00</span></div>
                                        <form class="variants clearfix">
                                            <input type="hidden">
                                            <button title="Add to Cart" class="add-to-cart btn btn-solid">Add to Cart</button>
                                        </form>
                                        
                                    </td>
                                    <td class="item-row">
                                        <img src="assets/images/product/fashion/3.jpg" alt="" class="featured-image">
                                        <div class="compare-lable">
                                            <span class="lable4">on sale</span>
                                        </div>
                                        <div class="product-price product_price"> <strong>On Sale: </strong><span>$89,00</span></div>
                                        <form class="variants clearfix">
                                            <input type="hidden">
                                            <button title="Add to Cart" class="add-to-cart btn btn-solid">Add to Cart</button>
                                        </form>
                                        
                                    </td>
                                    <td class="item-row">
                                        <img src="assets/images/product/fashion/4.jpg" alt="" class="featured-image">
                                        <div class="product-price product_price"> <strong>On Sale: </strong><span>$89,00</span></div>
                                        <form class="variants clearfix">
                                            <input type="hidden">
                                            <button title="Add to Cart" class="add-to-cart btn btn-solid">Add to Cart</button>
                                        </form>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <th class="product-name">Product Description</th>
                                    <td class="item-row">
                                        <p class="description-compare"> Add an extra dose of style with this raw look henley t-shirt from the house... </p>
                                    </td>
                                    <td class="item-row">
                                        <p class="description-compare"> Add an extra dose of style with this raw look henley t-shirt from the house... </p>
                                    </td>
                                    <td class="item-row">
                                        <p class="description-compare"> Add an extra dose of style with this raw look henley t-shirt from the house... </p>
                                    </td>
                                    <td class="item-row">
                                        <p class="description-compare"> Add an extra dose of style with this raw look henley t-shirt from the house... </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="product-name"> Availability </th>
                                    <td class="available-stock">
                                        <p> Available In stock </p>
                                    </td>
                                    <td class="available-stock">
                                        <p> Available In stock </p>
                                    </td>
                                    <td class="available-stock">
                                        <p> Available In stock </p>
                                    </td>
                                    <td class="available-stock">
                                        <p> Available In stock </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->