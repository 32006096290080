<app-breadcrumb [title]="'Review'" [breadcrumb]="'Review'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space blog-detail-page review-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <ul class="comment-section">
                    <li>
                        <div class="media"><img src="assets/images/review/1.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer
                                    faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique
                                    odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat
                                    lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus
                                    sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis
                                    erat tempor quis. Vestibulum eu vestibulum ex.</p>
                                <ul class="comnt-sec">
                                    <li><a href="#"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i><span>(14)</span></a></li>
                                    <li><a href="#">
                                            <div class="unlike"><i class="fa fa-thumbs-o-down" aria-hidden="true"></i>(2)</div>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media"><img src="assets/images/review/2.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer
                                    faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique
                                    odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat
                                    lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus
                                    sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis
                                    erat tempor quis. Vestibulum eu vestibulum ex.</p>
                                <ul class="comnt-sec">
                                    <li><a href="#"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i><span>(14)</span></a></li>
                                    <li><a href="#">
                                            <div class="unlike"><i class="fa fa-thumbs-o-down" aria-hidden="true"></i>(2)</div>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media"><img src="assets/images/review/3.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer
                                    faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique
                                    odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat
                                    lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus
                                    sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis
                                    erat tempor quis. Vestibulum eu vestibulum ex.</p>
                                <ul class="comnt-sec">
                                    <li><a href="#"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i><span>(14)</span></a></li>
                                    <li><a href="#">
                                            <div class="unlike"><i class="fa fa-thumbs-o-down" aria-hidden="true"></i>(2)</div>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media"><img src="assets/images/review/1.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer
                                    faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique
                                    odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat
                                    lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus
                                    sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis
                                    erat tempor quis. Vestibulum eu vestibulum ex.</p>
                                <ul class="comnt-sec">
                                    <li><a href="#"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i><span>(14)</span></a></li>
                                    <li><a href="#">
                                            <div class="unlike"><i class="fa fa-thumbs-o-down" aria-hidden="true"></i>(2)</div>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="media"><img src="assets/images/review/2.jpg" alt="Generic placeholder image">
                            <div class="media-body">
                                <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis
                                    fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer
                                    faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique
                                    odio mi, in volutpat metus posuere eu. Aenean suscipit ipsum nunc, id volutpat
                                    lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus
                                    sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis
                                    erat tempor quis. Vestibulum eu vestibulum ex.</p>
                                <ul class="comnt-sec">
                                    <li><a href="#"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i><span>(14)</span></a></li>
                                    <li><a href="#">
                                            <div class="unlike"><i class="fa fa-thumbs-o-down" aria-hidden="true"></i>(2)</div>
                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- section end -->