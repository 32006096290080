<div class="img-wrapper">
	<div class="lable-block">
		<span class="lable3" *ngIf='product.new'>new</span>
		<span class="lable4" *ngIf='product.sale'>on sale</span>
	</div>
	<div class="front">
        <a [routerLink]="['/products'+product?.url_product]">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
                 [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
                 class="img-fluid lazy-loading" alt="">
        </a>
	</div>
	<div class="cart-box">
		<button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
		<a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
            <i class="ti-heart" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
            <i class="ti-reload" aria-hidden="true"></i>
        </a>
	</div>
</div>
<div class="product-detail text-center ">
	<div class="rating">
		<bar-rating [rate]="5" [readOnly]="true" class="text-center pro-rating"></bar-rating>
	</div>
    <a [routerLink]="['/products'+product?.url_product]">
        <h6>{{ product.title | titlecase }}</h6>
    </a>
    <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
    </h4>
    <ul class="color-variant" *ngIf="Color(product?.variants).length">
      <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
        (click)="ChangeVariants(color, product)">
      </li>
    </ul>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
