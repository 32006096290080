import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Order, Orders } from "../classes/order";
import { HttpService } from "../../services/http.service";
import { ProductService } from "./product.service";
import { DataSharingService } from '../../services/data-sharing.service';


const state = {
    checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]'),
    transactions: JSON.parse(localStorage['transactions'] || '{}'),
}

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    order = new Orders();
    public orderDetails: Order = {};
    items: any[] = [];
    products: any[] = [];
    preferredLanguageSubscription: Subscription;

    constructor(
        private router: Router,
        private service: HttpService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
    ) {
        this.socket();
    }

    // Get Checkout Items
    public get checkoutItems(): Observable<any> {
        const itemsStream = new Observable(observer => {
            observer.next(state.checkoutItems);
            observer.complete();
        });
        return <Observable<any>>itemsStream;
    }

    // Create order
    // public createOrder(product: any, details: any, orderId: any, amount: any) {
    public createOrder(data) {
        let item = {
            shippingDetails: data.details,
            product: data.product,
            orderId: data.orderId,
            totalAmount: data.amount,
            subtotal: data.subtotal,
            tax: data.tax || 0,
            is_delivery: data.is_delivery || false,
            method_shipping: data.method_shipping || '',
            subtotalForRide: data.subtotalForRide || 0,
            service_fee: data.serviceFee || 0,
            used_credits: data.used_credits || 0,
        };
        state.checkoutItems = item;
        localStorage.setItem("checkoutItems", JSON.stringify(item));
        // localStorage.removeItem("cartItems");
        // this.router.navigate(['/shop/checkout/success', orderId]);
        return state;
    }

    processOrder(usuario, type = 0, data_wompi = []) {
        let user = usuario;
        // console.log("userLocal: ", user);
        this.checkoutItems.subscribe(response => {
            let uid = localStorage.getItem('orderId');
            if (uid == '' || uid == undefined) {
                uid = Date.now().toString();
                localStorage.setItem('orderId', uid);
            }
            // console.log('uid', uid);
            const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const WEEKS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            let now = new Date();
            let formatdate = WEEKS[now.getDay()] + ", " + now.getDate() + " of " + MONTHS[now.getMonth()] + " of " + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes();
            // console.log('response checkoutItems', response);
            // this.orderDetails = response;
            // localStorage.setItem('orderDetails', JSON.parse(response));
            this.order.amount = Number(response.subtotal.toFixed(2));
            response.product.forEach(element => {
                this.items.push({
                    image: element.images[0].src,
                    name: element.title,
                    quantity: element.quantity,
                    price: element.price_tax_product_discount,
                    discount: element.product_promote ? element.discount : 0,
                    sum: +(element.price_tax_product_discount * element.quantity).toFixed(2),
                    subtotal: +(element.price_tax_product_discount * element.quantity).toFixed(2),
                    uid: element.id,
                    description: element.description,
                    category_uid: element.category_uid,
                });
            })

            let place_delivery_user: any = {};
            let user_ubication: any = {};
            let commerce_ubication: any = {};

            if (response.is_delivery) {
                if (user.direction && user.direction.length > 0) {
                    user.direction.forEach((element: any) => {
                        if (element.is_default === true) {
                            place_delivery_user = element;
                            user_ubication = {
                                lat: place_delivery_user.lat_long._lat,
                                long: place_delivery_user.lat_long._long
                            };
                        }
                    });
                } else {
                    place_delivery_user = {};
                    user_ubication = {};
                }

                let commerceUbi = localStorage.getItem('commerce_ubication');

                if (commerceUbi !== '' && commerceUbi !== undefined && commerceUbi !== null) {
                    commerceUbi = JSON.parse(commerceUbi);
                    commerce_ubication = commerceUbi;
                } else {
                    commerce_ubication = {};
                }
            }

            this.order.items = this.items;
            this.order.place_delivery_user = place_delivery_user.name_place;
            this.order.direction = place_delivery_user.direction;
            this.order.user_ubication = user_ubication;
            this.order.commerce_ubication = commerce_ubication;
            this.order.who_receives = user.firstName + ' ' + user.lastName; // Edited 16-11-2023 | Leo
            this.order.uid = uid;
            this.order.user_uid = user.uid;
            this.order.commerce_uid = '';
            this.order.created_at_formatted = formatdate;
            this.order.customer_payment_uid = '';
            this.order.discount = 0;
            this.order.deparment = '';
            this.order.discount_percent = 0;
            this.order.subtotalForRide = Number(response.subtotalForRide.toFixed(2)) || 0;
            this.order.tax = Number(response.tax.toFixed(2)) || 0;
            this.order.service_fee = Number(response.service_fee.toFixed(2)) || 0;
            this.order.used_credits = Number(response.used_credits.toFixed(2)) || 0;
            this.order.tip_porcent = '0';
            this.order.total = response.totalAmount.toFixed(2) * 1;
            this.order.totalInCents = response.totalAmount.toFixed(2) * 100;
            this.order.tip_amount = 0;
            this.order.isGift = false;
            this.order.is_bitcoin = false;
            this.order.is_card = false;
            this.order.is_delivery = response.is_delivery;
            this.order.method_shipping = response.method_shipping;
            this.order.is_notified_client = true;
            this.order.is_notified_commerce = false;
            this.order.need_invoice = false;
            this.order.order_active = true;
            this.order.status_user = true;
            this.order.manager_uid = '';
            this.order.orders_status_uid = type == 0 ? 'New' : 'Payment Request';
            this.order.municipality = '';
            this.order.waytopay = localStorage.getItem('payment');
            this.order.invoicing = localStorage.getItem('invoicing');
            this.order.commerce_name = localStorage.getItem('sucursal');
            this.order.commerce_direction = localStorage.getItem('commerce_direction');
            this.order.shipping = localStorage.getItem('shipping');
            this.order.isWeb = true; // siempre sera true porque es proyecto desde la tienda web
            this.order.transactions = state.transactions; // added 03-05-2023
            this.order.data_wompi = data_wompi; // added by leo | 13-07-2023
            // console.log('info order send', this.order);

            let order_uid = localStorage.getItem('order_uid');
            // console.log('order_uid', order_uid);
            if (order_uid == '' || order_uid == null) {
                this.saveOrder().then(async res => {
                    if (localStorage.getItem("payment") != 'Wompi') {
                        localStorage.removeItem("cartItems");
                        localStorage.removeItem("orderId");
                        // window.location.href = `/shop/checkout/success/${localStorage.getItem('order_uid')}`;
                        await this.router.navigate(['/shop/checkout/success', localStorage.getItem('order_uid')]);
                    }
                });
            } else {
                this.updateOrder(order_uid).then(async res => {
                    if (localStorage.getItem("payment") != 'Wompi') {
                        localStorage.removeItem("cartItems");
                        localStorage.removeItem("orderId");
                        // window.location.href = `/shop/checkout/success/${localStorage.getItem('order_uid')}`;
                        await this.router.navigate(['/shop/checkout/success', localStorage.getItem('order_uid')]);
                    }
                });
            }

        });

    }

    saveOrder() {
        return new Promise((resolve, reject) => {
            let dataSave: any;
            this.service.saveOrder(this.order).then((res) => {
                dataSave = res;
                console.info('Save order', res)
                this.getStock().then(stock => {
                    setTimeout(() => {
                        console.log("update: ", stock);
                        this.updateStock(stock).then(res => {
                            console.log("update: ", stock);
                        })
                    }, 1e3);
                });
                console.log('dataSave._path.segments[1]', dataSave._path.segments[1])
                localStorage.setItem('order_uid', dataSave._path.segments[1])
                console.log(`localStorage.getItem('order_uid')`, localStorage.getItem('order_uid'))
                // localStorage.removeItem("cartItems");
                resolve(res);
            }, error => {
                reject(error);
                console.error('Error to get storing item', error)
            });
        })

    }

    updateOrder(order_uid) {
        return new Promise((resolve, reject) => {
            this.service.updateOrder(this.order, order_uid).then(res => {
                console.log('order update', res);
                this.getStock().then(stock => {
                    setTimeout(() => {
                        console.log("update: ", stock);
                        this.updateStock(stock).then(response => {
                            console.log("update: ", stock);
                            resolve(response);
                        })
                    }, 1e3);
                });
            })
        })
    }

    getStock(type = 0) {
        let update = [];
        let new_products_stock;
        return new Promise((resolve, reject) => {
            this.order.items.forEach(element => {
                this.service.getOneProduct(element.uid).subscribe(res => {

                    const clonedObject = { ...res };

                    new_products_stock = res.products_stock - element.quantity;
                    res.products_stock = new_products_stock;
                    res.old_products_stock = clonedObject.products_stock;
                    if (new_products_stock > 0) {
                        update.push(res);
                    } else {
                        res.product_availability = 'Out of Stock';
                        res.product_background_availability = 'danger';
                        update.push(res);
                    }
                });
            });
            resolve(update);
        })
    }

    async updateStock(stock) {
        try {
            for (const element of stock) {

                let clonedObject = { ...element };

                if ('old_products_stock' in element) {
                    delete element.old_products_stock;
                }

                let messagePost = {
                    products_stock: element.products_stock,
                }

                await this.service.updateProduct(messagePost, element.id);

                let dataProduct = clonedObject;

                let dataLog = {
                    reason: 'Update product - stock - ' + this.order.uid,
                    user_uid: this.order.user_uid,
                    name_user: this.order.who_receives,
                    date: Date.now(),
                    from: 'Tienda Online',
                    product_uid: element.id,
                    data_send: messagePost,
                    quantity_before: dataProduct.old_products_stock,
                    quantity_after: messagePost.products_stock,
                    price_before: dataProduct.price_tax_product,
                    price_after: dataProduct.price_tax_product,
                    discount_before: dataProduct.discount,
                    discount_after: dataProduct.discount,
                };

                let log = {
                    info: dataLog,
                    product_uid: element.id
                }

                console.time('save log');
                await this.productService.saveLog(log);
                console.timeEnd('save log');

                //console.log("sendPUT_Product: ", res);
            }
            return stock;
        } catch (error) {
            console.error('Error al actualizar el stock:', error);
            throw error; // Puedes volver a lanzar el error si lo deseas
        }
    }

    socket() {
        this.preferredLanguageSubscription = this.dataSharingService.transactions.subscribe((valor: any) => {
            state.transactions = valor;
        });
    }

}
