import {Component, EventEmitter, Inject, Input, Output, PLATFORM_ID} from '@angular/core';
import {Options} from "ng5-slider";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-diametro-exterior',
  templateUrl: './diametro-exterior.component.html',
  styleUrls: ['./diametro-exterior.component.scss']
})
export class DiametroExteriorComponent {

  // Using Output EventEmitter
  @Output() diametroExterior : EventEmitter<any> = new EventEmitter<any>();

  // define min, max and range
  @Input() min: number;
  @Input() max: number;

  public collapse: boolean = true;
  public isBrowser: boolean = false;

  public DE: any;

  options: Options = {
    floor: 0,
    ceil: 1000
  };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true; // for ssr
    }
    console.log('diametro exterio max 2', this.max);
  }

  ngOnInit(): void {
    // this.options.ceil = this.options.ceil < this.max ? this.max + 50 : this.options.ceil;
    // this.max = 1e3;
    console.log('diametro exterio max', this.max);
    if (this.options.ceil < this.max){
      let newOption: Options = {
        floor: 0,
        ceil: Number(Number(this.max) + 50),
      };
      this.options = newOption;
    }
  }

  // Range Changed
  appliedFilter(event: any) {
    this.DE = { minDE: event.value, maxDE: event.highValue };
    this.diametroExterior.emit(this.DE);
  }
}
